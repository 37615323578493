import { createElement } from "react";
import {
  ElementFactory,
  Serializer,
  QuestionRankingModel,
  defaultV2Css,
  SvgRegistry,
} from "survey-core";
import { ReactQuestionFactory, SurveyQuestionRanking } from "survey-react-ui";
import { localization } from "survey-creator-react";

export const RANKING_IMAGE_TYPE = "ranking-image";

defaultV2Css[RANKING_IMAGE_TYPE] = defaultV2Css["ranking"];


export class QuestionRankingImageModel extends QuestionRankingModel {
  constructor(name) {
    super(name);
    this.itemContentComponent = "custom-image-ranking-item";
  }

  getType() {
    return RANKING_IMAGE_TYPE;
  }
  get showTitle() {
    return this.getPropertyValue("showTitle");
  }
  set showTitle(val) {
    this.setPropertyValue("showTitle", val);
  }
}

export function registerRankingImage() {
  ElementFactory.Instance.registerElement(RANKING_IMAGE_TYPE, (name) => {
    return new QuestionRankingImageModel(name);
  });
}

const locale = localization.getLocale("");
locale.qt[RANKING_IMAGE_TYPE] = "Ranking Image";

Serializer.addClass(
  RANKING_IMAGE_TYPE,
  [
    {
      name: "showTitle",
      displayName: "Show image and video captions",
      type: "boolean",
      category: "general",
      default: true
    },
  ],
  function () {
    return new QuestionRankingImageModel("");
  },
  "ranking"
);

export class SurveyQuestionRankingImage extends SurveyQuestionRanking {}

SvgRegistry.registerIconFromSvg(
  RANKING_IMAGE_TYPE,
  `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <mask id="mask0_557_57308" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_557_57308)">
    <path d="M11.25 19V15.573C10.3821 15.4474 9.61763 15.108 8.95673 14.5548C8.29583 14.0016 7.84743 13.3064 7.61153 
    12.4692C6.45769 12.332 5.48398 11.8423 4.6904 11C3.8968 10.1577 3.5 9.15766 3.5 7.99998V7.3077C3.5 6.81058 3.67701 
    6.38503 4.03102 6.03103C4.38503 5.67701 4.81058 5.5 5.3077 5.5H7.32693V5.3077C7.32693 4.81058 7.50392 4.38503 7.85792 
    4.03103C8.21193 3.67701 8.63748 3.5 9.1346 3.5H14.8654C15.3625 3.5 15.788 3.67701 16.142 4.03103C16.496 4.38503 16.673 
    4.81058 16.673 5.3077V5.5H18.6923C19.1894 5.5 19.6149 5.67701 19.9689 6.03103C20.3229 6.38503 20.5 6.81058 20.5 
    7.3077V7.99998C20.5 9.15766 20.1032 10.1577 19.3096 11C18.516 11.8423 17.5423 12.332 16.3884 12.4692C16.1525 13.3064 
    15.7041 14.0016 15.0432 14.5548C14.3823 15.108 13.6179 15.4474 12.75 15.573V19H15.5576C15.7701 19 15.9483 19.0719 
    16.092 19.2157C16.2358 19.3595 16.3076 19.5377 16.3076 19.7503C16.3076 19.9629 16.2358 20.141 16.092 20.2846C15.9483 
    20.4282 15.7701 20.5 15.5576 20.5H8.44233C8.22983 20.5 8.0517 20.428 7.90795 20.2842C7.7642 20.1404 7.69233 19.9622 
    7.69233 19.7496C7.69233 19.537 7.7642 19.3589 7.90795 19.2154C8.0517 19.0718 8.22983 19 8.44233 19H11.25ZM7.32693 
    10.8577V6.99998H5.3077C5.21795 6.99998 5.14423 7.02883 5.08653 7.08653C5.02883 7.14423 4.99997 7.21795 4.99997 
    7.3077V7.99998C4.99997 8.69101 5.21857 9.30191 5.65575 9.83268C6.09293 10.3634 6.64999 10.7051 7.32693 10.8577ZM12.0023 
    14.1346C12.879 14.1346 13.6234 13.828 14.2355 13.2147C14.8477 12.6015 15.1538 11.8568 15.1538 10.9808V5.3077C15.1538 5.21795 
    15.125 5.14423 15.0673 5.08653C15.0096 5.02883 14.9359 4.99998 14.8461 4.99998H9.15383C9.06407 4.99998 8.99035 5.02883 8.93265 
    5.08653C8.87497 5.14423 8.84613 5.21795 8.84613 5.3077V10.9808C8.84613 11.8568 9.15297 12.6015 9.76665 13.2147C10.3803 13.828 
    11.1255 14.1346 12.0023 14.1346ZM16.673 10.8577C17.35 10.7051 17.907 10.3634 18.3442 9.83268C18.7814 9.30191 19 8.69101 19 
    7.99998V7.3077C19 7.21795 18.9711 7.14423 18.9134 7.08653C18.8557 7.02883 18.782 6.99998 18.6923 6.99998H16.673V10.8577Z" fill="#0E342E"/>
  </g>
</svg>`
);

ReactQuestionFactory.Instance.registerQuestion(RANKING_IMAGE_TYPE, (props) => {
  return createElement(SurveyQuestionRankingImage, props);
});

