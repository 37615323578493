import { intersection, difference, first, isEmpty } from "lodash";
import {
  Choice,
  ExecutionOptions,
  DynamicTextParams,
  PreviewOptions,
  EvaluateExpressionResult,
  ExecutionParams,
  PathParams,
  DynamicTextAction,
  DynamicTextResponseType,
  DynamicTextFormValue,
  DynamicTextOptions,
  DynamicTextOption,
} from "../types/dynamic-text.types";
import { Model, Question, SurveyModel } from "survey-core";
import { findQuestionById, getSurveyFromStorage, toName, toText, toValue } from "./survey.utils";
import { arrayToOptions, findOption } from "./form.utils";
import { buildAcceptTextQuestionName } from "../extensions/checkbox-item-extension";
import { isEmptyValue } from "./utils";
import { variablesStore } from "../../store/variables_store";
import { VariableTypes, CUSTOM_VARIABLE, H_VAR, C_VAR, hVariableOptionItems, QUESTION, IdentifierTypes, QUOTA } from "../types/variables.types";
import { INIT_DATA_TYPE } from "../custom-questions/init-data";
import { OptionItem } from "../types/form.types";
import { print } from "./utils";
import { QuestionType } from "../types/question.types";
const showdown = require("showdown");



const converter = new showdown.Converter();

const N_A_VALUE = "N/A";
const INVALID_VALUE = "Invalid";
const ERROR_VALUE = "ERROR";
export const EXPRESSION_SPLITTER = "/";
export const PATH_SPLITTER = ":";

export const INIT_DATA_FIELD = "init-data-field";

export const INPUT_TYPES_WITH_MULTI_CHOICES = [
  
  "checkbox",  
  "tagbox",
  "ranking",  
];

export const INPUT_TYPES_WITH_CHOICES = [
  "radiogroup",
  "checkbox",
  "dropdown",
  "tagbox",
  "ranking",
  QuestionType.rankingImage,
  QuestionType.imagepicker,
  "matrixdropdown",
  "matrixdynamic",
];
export const INPUT_TYPES_WITH_ANSWER_COUNT = [
  "checkbox",
  "tagbox",
  "multipletext",
];
export const INPUT_TYPES_WITH_SELECTED_CHOICES = [
  "radiogroup",
  "checkbox",
  "dropdown",
  "tagbox",
];
export const INPUT_TYPES_WITH_ACCEPT_TEXT = [
  "radiogroup",
  "checkbox",
];
export const NOT_SUPPORTED_ANSWER_INPUT_TYPES = [
  "matrix",
  "multipletext",
  "matrixdropdown",
  "matrixdynamic",
];
export const INPUT_TYPES_WITH_NESTED_PROPERTY = ["multipletext"];
export const INPUT_TYPES_WITH_ROW_AND_COL = [
  "matrix",
  "matrixdropdown",
  "matrixdynamic",
];

export const CHOICES_ACTION_TYPES = ["Choices", "SelectedChoices", "UnselectedChoices"];

export const runDynamicTextExpressionURL = (
  originalText: string,
  survey: SurveyModel
): string => {
  return transformText(originalText, survey, {
    // this function is being called by the flow engine redirect URL
    executionOption: ExecutionOptions.Display  
  });
};

export const runDynamicTextExpression = (
  originalText: string,
  survey: SurveyModel
): string => {
  return transformText(originalText, survey, {
     // this function is being called by logic processing which is different than display, this is only being called from Flow Engine execution
    // CAUTION CAUTION CAUTION: it will return weird responses if being used elsewhere
    executionOption: ExecutionOptions.Raw
  });
};

export const transformText = (
  originalText: string,
  survey: SurveyModel,
  options: any
): string => {
  let text: string = originalText;
  const expressions: string[] = findExpressions(text);

  expressions.forEach((expression: string) => {
    const cleanExpression = filterHTMLTags(expression);
    const resultOfExpression: EvaluateExpressionResult = evaluateExpression(
      cleanExpression,
      survey,
      options
    );

    print("+transformText", expression, "->", typeof resultOfExpression.result, resultOfExpression.result);
    
    let displayValue: string = resultOfExpression.result;
    if (options.executionOption === ExecutionOptions.Display) {
      if (resultOfExpression.valid) {
        if (options?.previewOption === PreviewOptions.Designer) {
          displayValue = addBraces(cleanExpression);
        } else {
          if (isEmptyExpressionValue(resultOfExpression.result)) {
            displayValue = options.previewOption === PreviewOptions.Taker ? N_A_VALUE : ERROR_VALUE;
          }
        }
      } else {
        displayValue = options.previewOption === PreviewOptions.Taker ? N_A_VALUE : INVALID_VALUE;
      }
    }

    if (options.executionOption !== ExecutionOptions.Raw) {
      displayValue = getRidOfQuotes(displayValue);
    }
    
    text = replaceAll(text, addBraces(expression), displayValue);
  });
  return text;
};

const loopTransformText = (
  text: number | string | undefined,
  survey: any,
  options: any,
  params: any,
  questionType: QuestionType
): number | string | undefined => {
  if (text && typeof text === "string" && findExpressions(text)?.length > 0) {
    return transformText(text, survey, options);
  }

  if (options.executionOption === ExecutionOptions.Raw &&
    (
      // Answer
      ([
        QuestionType.checkbox,
        QuestionType.ranking,
        QuestionType.rankingImage,
        QuestionType.imagepicker,
        QuestionType.tagbox,
      ].includes(questionType) && params.action === DynamicTextAction.Answer) ||
      // Choices
      CHOICES_ACTION_TYPES.includes(params.action)
    )
  )  {
    print("+text", text);
    text = (text && text !== `""`) ? `[${text}]` : `[]`;
  }

  return text;
};

export const addBraces = (text: string): string => "{" + text + "}";

export const getRidOfQuotes = (text: string): string => {
  if (`${text}`.startsWith('"') && `${text}`.endsWith('"')) {
    return text.slice(1, -1);
  }

  return text;
};

export const findExpressions = (text: string): string[] => {
  const regex = /\{.*?\}/g;
  const getRidOfBraces = (str: string): string => str.replace(/^\{|\}$/g, "");

  return (text.match(regex) || []).map(getRidOfBraces);
};

export const extractDynamicTextParams = (
  expression: string
): DynamicTextParams | null => {
  try {
    if (!expression) {
      return null;
    }

    return {
      expression,
      entityType: extractEntityType(expression),
      entityID: extractEntityID(expression),
      entityPath: extractEntityPath(expression),
      action: extractEntityAction(expression),
      property: extractEntityProperty(expression),
    };
  } catch (error) {
    return null;
  }
};

export const isValidExpression = (expression: string, survey: SurveyModel): boolean => {
  const params: DynamicTextParams | null = extractDynamicTextParams(expression);

  if (!params) {
    return false;
  }

  if (VariableTypes.includes(params.entityType)) return true;  

  if ( !expression || !params.entityType || !params.entityID || !params.action) return false;

  if ([QUESTION].includes(params.entityType) && params.action !== DynamicTextAction.InitData) {
    // TODO: Check if the question exists in the survey
    // the problem is that not all questions are in the survey (isVisible, initData)
    // we need to load raw json and look into it
    const question = survey.getQuestionByName(params.entityID);

    if (!question) {
      return false;
    }

    if (!params.action) {
      return false;
    }

    if (!DynamicTextOptions.map(v => v.value).includes(params.action)) {
      return false;
    }

  }

  return true;
};

const evaluateExpression = (
  expression: string,
  survey: SurveyModel,
  options: any
): EvaluateExpressionResult => {
  let result: number | string | undefined = `""`;
  const params: DynamicTextParams | null = extractDynamicTextParams(expression);
  let valid: boolean = true;

  if (
    isValidExpression(expression, survey) && params
  ) {
    switch (params.entityType) {
      case QUESTION:
      case CUSTOM_VARIABLE:
      case QUOTA:
        result = processQuestionExpression(params, survey, options);
        break;
      case H_VAR:
      case C_VAR:
        result = variablesStore.getValue(params.entityID as any);

        break;
      default:
        
        break;
    }
  } else {
    valid = false;
  }

  return {
    valid: valid,
    result: result
  };
};

export const extractEntityType = (expression: string): string => {
  let entityType = QUESTION;
  const parts = expression.split(EXPRESSION_SPLITTER);

  if (parts[0][0] === "h") {
    entityType = H_VAR;
  }
  if (parts[0][0] === "c" && parts[0][1] === "_") {
    entityType = C_VAR;
  }
  if (parts[0][0] === "v" && parts[0][1] === "_") {
    entityType = CUSTOM_VARIABLE;
  }
  if (parts[0][0] === "v" && parts[0][1] === "q" && parts[0][2] === "_") {
    entityType = QUOTA;
  }
  return entityType;
};

export const extractEntityID = (expression: string): string =>
  expression.split(EXPRESSION_SPLITTER)[0].split(PATH_SPLITTER)[0];

export const extractEntityPath = (expression: string): string[] => {
  const parts = expression.split(EXPRESSION_SPLITTER)[0].split(PATH_SPLITTER);

  parts.shift();

  return parts;
};

export const extractVariable = (expression: string): string => {
  return expression.replace("{}", "");
};

export const extractEntityAction = (expression: string): string =>
  expression.split(EXPRESSION_SPLITTER)[1];

export const extractEntityProperty = (expression: string): string =>
  expression.split(EXPRESSION_SPLITTER)[2];

export const processQuestionExpression = (
  params: DynamicTextParams,
  survey: SurveyModel,
  options: any
): number | string | undefined => {
  try {
    let result: any;
    // const model = new Model(survey.jsonObj);
    const question = survey.getQuestionByName(params.entityID);
    const questionType = question?.getType();
    const isDesignMode: boolean = survey.isDesignMode;
    const answer = !isDesignMode ? survey.data[params.entityID] : null;
    const pathParams = entityPathToParams(params.expression,question);
    const { row, col, property } = pathParams || {};

    if (params.entityType === QUESTION) {
      switch (params.action) {
        case DynamicTextAction.Counter:
          result = 0;

          if (answer) {
            result = answer || 0;
          }

          break;
        case "Answer":
          result = "";
          switch (questionType) {
            case QuestionType.quota:
            case QuestionType.counter:
              result = 0;
              
              if (answer) {
                  result = answer || 0;
              }

              break;
            case "matrix":
              if (params.entityPath?.length && answer) {
                if (params.entityPath?.length === 1 && row) {
                  result = answer[row];
                }

                if (params.entityPath?.length === 2 && row && col) {
                  result = answer[row][col];
                }
              }
              break;
            case "matrixdropdown":
              if (params.entityPath?.length && answer) {
                if (params.entityPath?.length === 1 && col) {
                  result = answer[col];
                }

                if (params.entityPath?.length === 2 && row && col) {
                  result = answer[row][col];
                }
              }
              break;
            case "multipletext":
              if (params.entityPath?.length && answer) {
                if (params.entityPath?.length === 1 && property) {
                  result = answer[property];
                }
              }
              break;
            default:
              result = answer;
              break;
          }
          break;

        case DynamicTextAction.FirstAnswer:
            const answerArray = answer; // Assuming the data is already in array format depending on question type
            if (Array.isArray(answerArray) && answerArray.length > 0) {
              result = answerArray[0];  // Get the first element from the array
            } else {
              result = ""; // or some default value indicating no answer
            }
            break;

        case DynamicTextAction.AnswerCount:
          result = 0;
          switch (questionType) {
            case "checkbox":
            case "tagbox":
              if (answer) {
                result = Object.values(answer).length;
              }
              break;

            default:
              if (params.entityPath?.length && answer) {
                result = 0;

                // For "AnswerCount" only column should be present in the expression
                // e.g. {question4:Column 1/AnswerCount}
                const col = params.entityPath[0];

                if (params.entityPath?.length === 1 && col) {
                  result = Object.values(answer).filter(
                    (selectedValue: any) => {
                      if (typeof selectedValue === "object") {
                        return selectedValue.hasOwnProperty(col);
                      } else {
                        return selectedValue === col;
                      }
                    }
                  ).length;
                }
              }
          }
          break;

        case "InitData":
          result = answer;
          break;

        case "QuestionText":
          result = filterHTMLTags(question?.title);
          break;

        case "Choices":
          result = mapChoicesByProp(getChoices(question));
          break;

        case "SelectedChoices":
          const selectedChoices = filterSelectedChoices(
            getChoices(question),
            answer
          );

          result = mapChoicesByProp(selectedChoices);
          break;

        case "UnselectedChoices":
          const unselectedChoices = filterUnselectedChoices(
            getChoices(question),
            answer
          );
          
          result = mapChoicesByProp(unselectedChoices);
          break;

        case DynamicTextAction.SelectedOrText:
          const selectedChoicesForSelectedOrText = filterSelectedChoices(
            getChoices(question),
            answer
          );
          const values: string[] = [];

          selectedChoicesForSelectedOrText.map((choice: any) => {
            const commentQuestionName = buildAcceptTextQuestionName(question, choice);
            const commentValue = survey.data[commentQuestionName];

            if (choice.acceptText) {
              if (commentValue) {
                values.push(commentValue);
              } else {
                values.push(choice.text);
              }
            } else {
              values.push(choice.text);
            }
          });
          
          result = values;
          break;
        
        case DynamicTextAction.FirstSelectedOrText:
          if (Array.isArray(answer)) {
            const firstSelectedChoice : any = filterSelectedChoices(
              getChoices(question),
              [answer[0]]
            )[0]; // Get the first selected choice
          
            if (Array.isArray(answer) && answer.length > 0 && firstSelectedChoice) {
              const commentQuestionName = buildAcceptTextQuestionName(question, firstSelectedChoice);
              const commentValue = survey.data[commentQuestionName];
          
              if (firstSelectedChoice.acceptText && commentValue) {
                result = commentValue; // Use the comment value if available and applicable
              } else {
                result = firstSelectedChoice.text; // Otherwise, use the choice's text
              } 
            } else {
              if (Array.isArray(answer) && answer.length > 0)
                {
                  result = answer[0];
                }else{
                  result = ""; // or some default value indicating no selection

                }
              
            }
          }

          break;

        default:
          result = "";
          break;
      }
    } else
    if ([CUSTOM_VARIABLE, QUOTA].includes(params.entityType)) {
      result = survey.data[params.entityID];
    }
    return (
      loopTransformText(prettyAnswer(result,options), survey, options, params, questionType)
    );
  } catch (error) {
    return "";
  }
};

const prettyAnswer = (answer: any, options:any): number | string | undefined => {
  if (!isEmptyValue(answer)) {
    if (Array.isArray(answer)) {
      if (options.executionOption == ExecutionOptions.Raw ){
        const escapeQuotes = (str: string): string => str.replace(/"/g, '\\"');
        const values = answer.map((item: any) => {
          
        if (typeof(item) == "object") {
          return `"${escapeQuotes(item?.value || "")}"`
        } else {
          return `"${escapeQuotes(item || "")}"`;
        }
        });
  
        return `${values.join(", ")}`;

      }else{
        const values = answer.map((item: any) => {
        if (typeof(item) == "object") {
          return item?.value || ``;
        } else {
          return item || ``;
        }
        });
  
        return `"${values.join(", ")}"`;

      }
      
    } else {
      return `"${answer}"`;
    }
  } else {
    return `""`;
  }
};

const isEmptyExpressionValue = (answer: number | string | undefined): boolean => {
  if (answer === ``) {
    return true;
  }

  if (answer === `""`) {
    return true;
  }

  return false;
};

export const getChoices = (question: Question): Choice[] => {
  if (!question) {
    return [];
  }

  const obj = question["jsonObj"];

  if (INPUT_TYPES_WITH_CHOICES.includes(obj.type)) {
    return question.choices;
  }

  return [];
};

export const getRateValues = (question: Question): Choice[] => {
  if (!question) {
    return [];
  }

  const obj = question["jsonObj"];

  return question.rateValues;
};

const mapChoicesByProp = (
  choices: Array<Choice>,
): any[] => {
  if (!choices) {
    return [];
  }

  return choices.map((choice: Choice) => {
    return choice.text;
  });
};

export const mapChoicesToOptionItem = (
  choices: Array<Choice>,
  useTextAsValue: boolean = false
): OptionItem[] => {
  if (!choices) {
    return [];
  }

  return choices.map((choice: Choice) => {
    return {
      value: useTextAsValue ? choice.text : choice.value,
      label: choice.text,
    };
  });
};

const filterSelectedChoices = (
  choices: Array<Choice>,
  values: string[],
): Array<Choice> => {
  if (!choices || !values) {
    return [];
  }

  return choices.filter((choice: Choice) => {
    return values.includes(choice.value || choice.text);
  });
};

const filterUnselectedChoices = (
  choices: Array<Choice>,
  values: string[],
): Array<Choice> => {
  if (!choices) {
    return [];
  }

  if (!values) {
    return choices;
  }

  return choices.filter((choice: Choice) => {
    return !values.includes(choice.value || choice.text);
  });
};

export const containsHtml = (text: string): boolean => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;

  return htmlRegex.test(text);
};

export const convertHtmlToText = (html: string): string => {
  //convert the markdown text to html
  let str = converter.makeHtml(html);

  if (str.indexOf("<p>") === 0) {
      //remove root paragraphs<p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
  }

  //set html
  return str;
};

export function filterHTMLTags(originalHtml: string, allowedTags: string[] = []): string {
  let filteredHTML = originalHtml;

  if (!filteredHTML) {return ""};

  // Replace &nbsp; with a space
  filteredHTML = filteredHTML.replace(/&nbsp;/g, ' ');
  // filteredHTML = filteredHTML.replace(/&lt;/g, '<');
  // filteredHTML = filteredHTML.replace(/&gt;/g, '>');
  // filteredHTML = filteredHTML.replace(/&#47;/g, '/');
  // filteredHTML = filteredHTML.replace(/&#47;/g, '/');
  // // Replace all HTML entities with an empty space
  // filteredHTML = filteredHTML.replace(/&[a-zA-Z0-9#]+;/g, '');
  // decodeHtml


  const htmlEntityRegex = /&[a-zA-Z0-9#]+;/g;

  // Function to replace each HTML entity
  const replacer = (entity: string): string => handleSpecialCharacter(entity) as string;

  // Replace all HTML entities using the replacer function
  filteredHTML = filteredHTML.replace(htmlEntityRegex, replacer);

  // Use showdown to parse the HTML
  const parsedHTML = converter.makeHtml(filteredHTML);

  // Iterate through the parsed HTML and filter tags
  let inTag = false;
  let currentTag = '';
  filteredHTML = '';
  
  for (let i = 0; i < parsedHTML.length; i++) {
      const char = parsedHTML[i];
      if (char === '<') {
          inTag = true;
          currentTag = '';
      }

      if (inTag) {
          currentTag += char;
      } else {
          filteredHTML += char;
      }

      if (char === '>') {
          inTag = false;
          if (allowedTags.includes(currentTag.toLowerCase())) {
              filteredHTML += currentTag;
          }
      }
  }

  return filteredHTML;
}

function escapeRegExp(string: any) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str: any, find: any, replace: any) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const handleSurveyResultsTableMarkup = () => {
  return setInterval(() => {
    [
      ...(document.querySelectorAll(
        `.svd-test-results .svd-test-results__table table td`
      ) as any),
    ].forEach((el: any) => {
      if (containsHtml(el.innerText)) {
        el.innerText = filterHTMLTags(el.innerText);
      }
    });
  }, 100);
};

export function doMarkdown(survey: any, options: any, executionParams: ExecutionParams) {
  const html = transformText(`${options.text}`, survey, {...options, ...executionParams});

  options.html = convertHtmlToText(html);
}

export const joinPath = (array: any[]): string => {
  return array.filter((a) => a).join(PATH_SPLITTER);
};

export const entityPathToParams = (
  expression: string,
  question: Question | undefined
): PathParams | null => {
  const params: DynamicTextParams | null = extractDynamicTextParams(expression);

  if (!params) {
    return null;
  }

  const questionType: string = question?.getType() || "text";

  let { row, col, property }: any = {};

  switch (params.action) {
    case "Answer":
      switch (questionType) {
        case "matrix":
        case "matrixdropdown":
          if (params.entityPath?.length) {
            if (params.entityPath?.length === 1 && params.entityPath[0]) {
              row = params.entityPath[0];
            }

            if (
              params.entityPath?.length === 2 &&
              params.entityPath[0] &&
              params.entityPath[1]
            ) {
              row = params.entityPath[0];
              col = params.entityPath[1];
            }
          }
          break;
        case "multipletext":
          if (params.entityPath?.length) {
            if (params.entityPath?.length === 1 && params.entityPath[0]) {
              property = params.entityPath[0];
            }
          }
          break;
        default:
          if (params.entityPath?.length) {
            property = params.entityPath[0];
          }
          break;
      }
      break;

    case DynamicTextAction.AnswerCount:
      if (params.entityPath?.length) {
        if (params.entityPath?.length === 1 && params.entityPath[0]) {
          col = params.entityPath[0];
        }
      }
      break;
    default:
      break;
  }

  return { row, col, property };
};

export const handleSpecialCharacter = (inputString: string | undefined) => {
  if (!inputString) return inputString;
  const parser = new DOMParser();
  const decodedDocument = parser.parseFromString(inputString, "text/html");
  const text = decodedDocument?.body?.textContent || '';
  return text;
}

export const generateDynamicTextExpression = (
  lhs: DynamicTextFormValue
): string => {
  if (isEmpty(lhs) || !lhs.identifier) {
    return "";
  }

  const fields: string[] = [
    "identifier",
    "row",
    "col",
    "property",
    "action",
  ];

  let query: string = "";
  
  fields.forEach((key: string, index: number) => {
    if (["row", "col", "property"].includes(key)) {
      //@ts-ignore
      query += lhs[key] ? `:${lhs[key]}` : "";
    } else {
      //@ts-ignore
      query += lhs[key] ? `${index !== 0 ? "/" : ""}${lhs[key]}` : "";
    }
  });
  
  return addBraces(query);
};

// Not used
export const getDynamicTextResponseType = (values: DynamicTextFormValue): DynamicTextResponseType => {
  const {type, identifier, row, col, property, action} = values;
  let responseType: DynamicTextResponseType = DynamicTextResponseType.String;

  if (type === IdentifierTypes.Question) {
    const question: Question | undefined = findQuestionById(identifier, "");
    const questionType: QuestionType = question.getType();
    
    switch (action) {
      case DynamicTextAction.Counter:
      case DynamicTextAction.AnswerCount:
        responseType = DynamicTextResponseType.Number;
        break;

      case DynamicTextAction.Answer:
        switch (questionType) {
          case QuestionType.boolean:
            responseType = DynamicTextResponseType.Boolean;
            break;
          case QuestionType.counter:
          case QuestionType.quota:
            responseType = DynamicTextResponseType.Number;
            break;
        
          default:
            responseType = DynamicTextResponseType.String;
            break;
        }
        break;

      case DynamicTextAction.Choices:
      case DynamicTextAction.SelectedChoices:
      case DynamicTextAction.UnselectedChoices:
        responseType = DynamicTextResponseType.ListOfStrings;
        break;
    
      default:
        responseType = DynamicTextResponseType.String;
        break;
    }
  }

  return responseType;
};

export const filterQuestionsByType = (identifier: Question, type: string): boolean => {
  if ([
    QuestionType.panel,
    QuestionType.paneldynamic,
    QuestionType.pagebreak,
  ].includes(identifier.getType())) {
    return false;
  }

  if (type === IdentifierTypes.Question &&
      identifier.getType() !== INIT_DATA_TYPE) {
    return true;
  }

  return true;
};

export const filterChoicesByQuestionType = (
  formValue: DynamicTextFormValue,
  surveyId: any
): OptionItem[] => {
  const entityType: string = formValue?.type;
  const questionName = formValue?.identifier?.value || formValue?.identifier;
  const identifier: Question | undefined = findQuestionById(questionName, surveyId);
  const questionType: QuestionType = identifier?.getType();
  const row: string | undefined = formValue?.row;
  const col: string | undefined = formValue?.col;
  const property: string | undefined = formValue?.property;

  let results: OptionItem[] = [];

  switch (questionType) {
    case INIT_DATA_TYPE:
      results.push(...[DynamicTextOption.InitData]);
      break;
    case QuestionType.constantSum:
      results = [DynamicTextOption.Input, DynamicTextOption.QuestionText];
      break;
    case QuestionType.quota:
    case QuestionType.counter:
      results = [DynamicTextOption.Input, DynamicTextOption.QuestionText, DynamicTextOption.Counter];
      break;
    case "matrix":
    case "matrixdropdown":
    case "multipletext":
      results.unshift(...filterChoicesByQuestionTypeForQuestionsWithProps(formValue, surveyId));
      break;
    // add additional case statements for radio, multi-select, push answer count as an option
    default:
      results = [DynamicTextOption.Input, DynamicTextOption.QuestionText, DynamicTextOption.Answer];

      if (INPUT_TYPES_WITH_ANSWER_COUNT.includes(questionType)) {
        results.push(...[DynamicTextOption.AnswerCount]);
      }

      if (INPUT_TYPES_WITH_CHOICES.includes(questionType)) {
        results.push(...[DynamicTextOption.Choices]);
      }

      if (INPUT_TYPES_WITH_SELECTED_CHOICES.includes(questionType)) {
        results.push(
          ...[
            DynamicTextOption.SelectedChoices,
            DynamicTextOption.UnselectedChoices,
          ]
        );
      }

      if (INPUT_TYPES_WITH_ACCEPT_TEXT.includes(questionType)) {
        results.push(...[DynamicTextOption.SelectedOrText]);
      }

      if (INPUT_TYPES_WITH_MULTI_CHOICES.includes(questionType)) {
        results.push(...[DynamicTextOption.FirstAnswer, DynamicTextOption.FirstSelectedOrText]); // Include FirstAnswer with similar identifier types
      }

      break;
  }

  return results;
};

export const filterChoicesByQuestionTypeForQuestionsWithProps = (
  formValue: DynamicTextFormValue,
  surveyId: any
): OptionItem[] => {
  const entityType: string = formValue?.type;
  const questionName = formValue?.identifier?.value || formValue?.identifier;
  const identifier: Question | undefined = findQuestionById(questionName, surveyId);
  const questionType: string = identifier?.getType();
  const row: string | undefined = formValue?.row;
  const col: string | undefined = formValue?.col;
  const property: string | undefined = formValue?.property;

  let results: OptionItem[] = [];

  switch (questionType) {
    case "matrix":
      if (row && !col) {
        results.push(...[DynamicTextOption.Answer]);
      }

      if (!row && col) {
        results.push(...[DynamicTextOption.AnswerCount]);
      }

      if (!row && !col) {
        results.unshift(...[DynamicTextOption.QuestionText]);
      }
      break;
    case "matrixdropdown":
      if (row && col) {
        results.push(...[DynamicTextOption.Answer]);
      }

      if (!row && col) {
        results.push(...[DynamicTextOption.AnswerCount]);
      }

      if (!row && !col) {
        results.unshift(...[DynamicTextOption.QuestionText]);
      }
      break;
    case "multipletext":
      if (property) {
        results.push(...[DynamicTextOption.Answer]);
      } else {
        results.unshift(...[DynamicTextOption.QuestionText]);
      }
      break;
    // add additional case statements for radio, multi-select, push answer count as an option
    default:
      results = [];

      break;
  }

  return results;
};

export const preparePathFields = (formValue: DynamicTextFormValue): any => {
  const result: any = {
    rows: [],
    cols: [],
    props: []
  };

  const identifier: Question | undefined = findQuestionById(
    formValue?.identifier,
    ""
  );

  if (isQuestionWithRowAndCol(formValue)) {
    result.rows = arrayToOptions(identifier?.rows, toText, toValue);
    result.cols = arrayToOptions(identifier?.columns, toText, toValue);
  }

  if (isQuestionWithNestedProperty(formValue)) {
    result.props = arrayToOptions(identifier?.items, toName, toName);
  }

  return result;
};

export const isQuestionWithPath = (formValue: DynamicTextFormValue): boolean => {
  if (formValue.type !== IdentifierTypes.Question) {
    return false;
  }

  if (!formValue.identifier) {
    return false;
  }

  return (
    isQuestionWithNestedProperty(formValue) ||
    isQuestionWithRowAndCol(formValue)
  );
};

const isQuestionWithNestedProperty = (
  formValue: DynamicTextFormValue
): boolean =>
  _isQuestionBelongsToGroup(formValue, INPUT_TYPES_WITH_NESTED_PROPERTY);

const isQuestionWithRowAndCol = (formValue: DynamicTextFormValue): boolean =>
  _isQuestionBelongsToGroup(formValue, INPUT_TYPES_WITH_ROW_AND_COL);

const _isQuestionBelongsToGroup = (
  formValue: DynamicTextFormValue,
  group: string[]
): boolean => {
  if (formValue.type !== IdentifierTypes.Question) {
    return false;
  }

  const identifier: Question | undefined = findQuestionById(
    formValue?.identifier?.value || formValue?.identifier,
    ""
  );

  if (!formValue?.identifier || !identifier) {
    return false;
  }

  return group.includes(identifier.getType());
};

export const isDynamicTextFormValid = (formValue: DynamicTextFormValue): boolean => {
  if (isEmpty(formValue) || !formValue.type || !formValue.identifier) {
    return false;
  }

  if (formValue.type === IdentifierTypes.Question) {
    return !!formValue.action;
  }

  if (formValue.type === IdentifierTypes.Variable) {
    return !!formValue.identifier;
  }

  return true;
};