import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import TakeSurvey from "./components/SurveyTaker/SurveyTaker";
function App() {

  useEffect(() => {
  }, []);

    return (
      <>
        <Router>
          <Routes>
              <Route path="/ts/ut" element={<TakeSurvey />}/>
              <Route path="/take-survey/:orgId/:token" element={<TakeSurvey />}/>
          </Routes>
        </Router>
      </>
    );
}

export default App;
