import { ItemValue } from "survey-core";

export const registerCustomChoiceComponent = (options: any) => {
    const question = options.question;
    if (['radiogroup', 'checkbox'].includes(question.getType())) {
        question.itemComponent = `${question.getType()}-item-ext`;
    }
};

const clearValueIfInvisible = (sender: any, options: any) => {
    const survey = sender;
    const question = options.question;
    if (question?.choices) {
        question.choices.forEach((choice: any) => {
            if (question.getType() === 'checkbox' && !question.value.includes(choice.value)) {
                choice.comment = null;
                survey.setValue(`${question.name}-${choice.value}-Comment`, null);
            }

            if (question.getType() === 'radiogroup' && question.value !== choice.value) {
                choice.comment = null;
                survey.setValue(`${question.name}-${choice.value}-Comment`, null);
            }
        });
    }
}

const updateChoices = (src: any, dest: any, choicesFromQuestionMode: string) => {
    if (dest.isDesignMode) return (dest.choices = []);
    const choices = src.visibleChoices.filter((c: any) => choicesFromQuestionMode === 'selected' ? c.selected : choicesFromQuestionMode === 'unselected' ? !c.selected : true);
    const newChoices = [];
    if (dest?.isOtherCarryForwarded) dest.showOtherItem = false;
    for (let i = 0; i < choices.length; i++) {
        let item = choices[i];
        const comment = item.value === 'other' ? src.comment || item.text : item?.comment || item.text || null;
        if (comment) {
            item = new ItemValue(item.value, comment);
            choices[i].acceptText && (item.acceptText = false);
        }
        !['none', 'other'].includes(item.value) && newChoices.push(item);
        
        if (item.value === 'other' && !dest.showOtherItem) {
            dest.showOtherItem = true;
            dest.isOtherCarryForwarded = true;
            if (!dest.oldOtherText) dest.oldOtherText = src.otherText;
            dest.otherText = item.comment || dest.oldOtherText;
        }
    }

    if (dest.showOtherItem) {
        if (!dest.oldOtherText) dest.oldOtherText = dest.otherText;
        dest.otherText = src.comment ? src.comment : dest.oldOtherText;
    }

    dest.value = null;
    dest.choices = newChoices;
}

function updateRows(src: any, dest: any, choicesFromQuestionMode: string) {
    if (dest.isDesignMode) return;
    const choices = src.visibleChoices.filter((c: any) => choicesFromQuestionMode === 'selected' ? c.selected : choicesFromQuestionMode === 'unselected' ? !c.selected : true);
    const rows: { value: any; text: any; }[] = [];
    choices.forEach((c: any) => {
        const comment = c.value === 'other' ? src.comment : c?.comment || c.text || null;
        !['none'].includes(c.value) && rows.push({value: c.value, text: comment});
    });
    dest.rows = rows;
}

const carryforwardChoices = (sender: any, options: any) => {
    let question = options?.question;

    if (!question) return;

    if (!question && options?.name?.includes('-Comment')) {
        question = sender.getQuestionByName(options.name.split('-')[0]);
    }
    if (!question || !question.choices) return;
    const questions = sender.getAllQuestions();
    for (let i = 0; i < questions.length; i++) {
        if (questions[i].selectChoicesFromQuestion === question.name) {
            if ( questions[i].visibleIf && !sender.runCondition(questions[i].visibleIf)) return;
            updateChoices(question, questions[i], questions[i].choicesFromQuestionMode);
            updateDependantQuestionChoices(questions[i], questions);
        }
    }
}

const updateDependantQuestionChoices = (question:any, questions:any) => {
    for (let i = 0; i < questions.length; i++) {
        if (questions[i].selectChoicesFromQuestion === question.name) {
            updateChoices(question, questions[i], questions[i].choicesFromQuestionMode)
        }

        if (questions[i].copyChoicesAsRowsFrom === question.name) {
            updateRows(question, questions[i], questions[i].choicesFromQuestionMode)
        }
    }
}

function carryForwardRows(sender: any, options: any) {
    let question = options?.question;
    
    if (!question) return;

    if (!question && options?.name?.includes('-Comment')) {
        question = sender.getQuestionByName(options.name.split('-')[0]);
    }
    if (!question || !question.choices) return;
    const questions = sender.getAllQuestions();
    for (let i = 0; i < questions.length; i++) {
        if (['matrix', 'matrixdropdown'].includes(questions[i].getType()) && questions[i].copyChoicesAsRowsFrom === question.name) {
            if ( questions[i].visibleIf && !sender.runCondition(questions[i].visibleIf)) return;
            updateRows(question, questions[i], questions[i].choicesFromQuestionMode);
        }
    }
}

export const onValueChangedHandler = (sender: any, options: any) => {
    clearValueIfInvisible(sender, options);
    carryforwardChoices(sender, options);
    carryForwardRows(sender, options);
};

export const updateDependantQuestion = (sender: any, options: any) => {
    const question = options.question;
    if (question.selectChoicesFromQuestion && ['all', 'unselected'].includes(question.choicesFromQuestionMode)) {
        const carryForwardQuestion = sender.getQuestionByName(question.selectChoicesFromQuestion);
        updateChoices(carryForwardQuestion, question, question.choicesFromQuestionMode);
        return;
    }

    if (question.copyChoicesAsRowsFrom && ['all', 'unselected'].includes(question.choicesFromQuestionMode)) {
        const carryForwardQuestion = sender.getQuestionByName(question.copyChoicesAsRowsFrom);
        updateRows(carryForwardQuestion, question, question.choicesFromQuestionMode);
        return;
    }
}