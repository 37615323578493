import React from "react";
import { Base, SurveyModel, PageModel } from "survey-core";
import {
  ReactElementFactory,
  SurveyElementBase,
  SurveyHeader, SvgIcon
} from "survey-react-ui";
import { SurveyCreatorModel, TabDesignerViewModel } from "survey-creator-core";
import { SurveyPageNavigator } from "../PageNavigator";
import { SurveyNavigation } from "../Navigation";

interface ITabDesignerComponentProps {
  data: TabDesignerViewModel;
}

export class TabDesignerComponent extends SurveyElementBase<ITabDesignerComponentProps, any> {
  state = {
    newSectionName: 'New Section'
  }
  private get model(): TabDesignerViewModel {
    return this.props.data;
  }
  protected get creator(): SurveyCreatorModel {
    return this.model.creator;
  }

  private denyUpdate = () => {
    this.denyComponentUpdate();
  }

  private allowUpdate = () => {
    this.allowComponentUpdate();
  }
  private setNewSectionName = value => {
    this.setState({ newSectionName: value })
  }

  private createNewPage = () => {
    // @ts-ignore
    const page = this.props.survey.addNewPage('page1')
    page.title = this.state.newSectionName;
    page.addNewQuestion("text", null, 0)
  }

  private addDragDropEvents = () => {
    this.creator.onDragStart.add(this.denyUpdate);
    this.creator.onDragEnd.add(this.allowUpdate);
  }

  private clearDragDropEvents = () => {
    this.creator.onDragStart.remove(this.denyUpdate);
    this.creator.onDragEnd.remove(this.allowUpdate);
  }

  componentDidMount(): void {
    super.componentDidMount();
    this.addDragDropEvents();
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    this.clearDragDropEvents();
    super.componentWillUnmount();
  }

  protected getStateElements(): Array<Base> {
    return [this.model, this.model.survey, this.model.pagesController];
  }

  private renderedPagesCache: any = {};
  protected getRenderedPages(): JSX.Element[] {
    if (this.changedStatePropName !== "pages") {
      this.renderedPagesCache = {};
    }
    const renderedPages = [];

    if (this.creator.pageEditMode !== "bypage") {
      const pages = this.creator.survey.pages;

      pages.forEach((page, index) => {
        let cachedPage = this.renderedPagesCache[page.id];
        if (!cachedPage) {
          cachedPage = this.createRenderedPage(page, index);
          this.renderedPagesCache[page.id] = cachedPage;
        }
        renderedPages.push(cachedPage);
      });

      // if (this.model.showNewPage) {
      //   renderedPages.push(this.renderNewPage("svc-page", this.model.newPage.id + "-ghost-new-page"));
      // }
    } else {
      const page2Display = this.model.pagesController.page2Display;
      if (!!page2Display) {
        let cachedPage = this.renderedPagesCache[page2Display.id];
        if (!cachedPage) {
          cachedPage = this.createRenderedPage(page2Display, 0, this.model.newPage === page2Display);
          this.renderedPagesCache[page2Display.id] = cachedPage;
        }
        renderedPages.push(cachedPage);
      }
    }

    return renderedPages;
  }
  protected addSection(e: any, index: number) {
    e.stopPropagation()
    function ensureUniqueName(names: string[], newName: string): string {
      // Check if the newName already contains a number at the end
      const namePattern = /^(.*?)(\d+)?$/;
      const match = newName.match(namePattern);

      if (!match) {
        return newName;
      }

      let baseName = match[1];
      let count = match[2] ? parseInt(match[2], 10) : 1;

      // Initialize uniqueName with the base name
      let uniqueName = `${baseName}${count}`;

      // Increment count and generate a new unique name until one is found
      //@ts-ignore
      while (names.includes(uniqueName)) {
        count++;
        uniqueName = `${baseName}${count}`;
      }

      // Return the unique name
      return uniqueName;
    }
    function ensureUniqueTitle(names: any[], newName : string) {
      // @ts-ignore
      if (!names.length || !names.includes(newName)) {
        return newName;
      }

      let count = 1;
      let uniqueName = `${newName}(${count})`;
      // @ts-ignore
      while (names.includes(uniqueName)) {
        count++;
        uniqueName = `${newName}(${count})`;
      }

      return uniqueName;
    }
    // @ts-ignore
    const names = this.creator.survey.pages.map(i => i.propertyHash.name)
    // @ts-ignore
    const titles = this.creator.survey.pages.map(i => i.title)
    let uniqueName = ensureUniqueName(names, 'page1');
    let uniqueTitle = ensureUniqueTitle(titles, 'New Section');
    const page = this.creator.survey.addNewPage(uniqueName, index + 1)
    page.title = uniqueTitle;
    page.addNewQuestion("text", null, 0)
    this.creator.selectElement(page, undefined, true)
    setTimeout(() => {
      const parent = document.getElementById(page.id);
      const container = parent.querySelector(`[aria-label="${uniqueTitle}"]`);
      const spanElement = container.querySelector('span[role="textbox"]');
      // @ts-ignore
      spanElement.click();
    }, 100)
  }

  protected addQuestion(e: any, page: PageModel) {
    e.stopPropagation()
    page.addNewQuestion("text", null, 0)
  }

  protected createRenderedPage(page: PageModel, index: number, isGhostPage?: boolean): any {
    // @ts-ignore
    const countOfQuestions = page.questions.length;
    // @ts-ignore
    return (
      <div
        className={"svc-page"}
        data-sv-drop-target-page={page.name}
        data-sv-drop-target-survey-element={isGhostPage ? "newGhostPage" : page.name}
        key={page.id + "-" + index}
      >
        {this.renderPage(page)}
        <div className="svc-add-section-container">
          <div className="svc-add-section-container__addSectionBtn_container">
            <SvgIcon
                size={16}
                iconName={"icon-plus"}>
            </SvgIcon>
            <span className="svc-add-section-container__addSectionBtn_button" onClick={(e) => this.addSection(e, index)}> Add Section</span>
          </div>
          <div className="svc-add-section-container__addSectionBtn_container_second">
            <hr />
          </div>
        </div>
      </div>
    );
  }
  private renderNewPage(className: string, key: string = "") {
    return (
      <React.Fragment key={key}>
        <div
          className={className}
          data-sv-drop-target-survey-element={"newGhostPage"}
        >
          {!!this.model.newPage ? this.renderPage(this.model.newPage) : null}
        </div>
      </React.Fragment>);
  }
  protected renderPage(pageV: PageModel): JSX.Element {
    return ReactElementFactory.Instance.createElement("svc-page", { survey: this.creator.survey, page: pageV, creator: this.creator });
  }
  renderElement(): JSX.Element {
    const designerTabClassName = "svc-tab-designer " + this.model.getRootCss();

    return (
      <React.Fragment>
        <div className="svc-flex-column">
          {this.model.isToolboxVisible ? ReactElementFactory.Instance.createElement("svc-adaptive-toolbox", { model: this.creator }) : null}
        </div>
        <div className={designerTabClassName} onClick={() => this.model.clickDesigner()}>
          <div className="svc-tab-designer_content">
            {this.model.showPlaceholder ? this.renderPlaceHolder() : this.renderTabContent()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderHeader(condition: boolean): JSX.Element {
    if (!condition) return null;

    const survey: SurveyModel = this.creator.survey;
    return (<React.Fragment>
      <div className="svc-designer-header">
        <SurveyHeader survey={survey}></SurveyHeader>
      </div>
    </React.Fragment>);
  }
  renderPlaceHolder(): JSX.Element {
    const surveyHeader = this.renderHeader(this.creator.allowEditSurveyTitle && this.creator.showHeaderInEmptySurvey);

    return (<React.Fragment>
      {surveyHeader}
      <div className="svc-designer__placeholder-container" data-sv-drop-target-survey-element={"newGhostPage"}>
        <div className="placeholder__container_new">
          <input autoFocus className="spg-input spg-text" type="text" value={this.state.newSectionName} onChange={e => this.setNewSectionName(e.target.value)}/>
          <p className="placeholder__container_text">Get started by adding your first question:</p>
          <button disabled={!this.state.newSectionName} className="placeholder__container_btn" onClick={() => this.createNewPage()}>Create New Question</button>
        </div>
      </div>
    </React.Fragment>);
  }
  renderPlaceHolderContent(): JSX.Element {
    return <span className="svc-designer-placeholder-text svc-text svc-text--normal">{this.model.placeholderText}</span>;
  }
  renderTabContent(): JSX.Element {
    const survey: SurveyModel = this.creator.survey;
    const surveyHeader = this.renderHeader(this.creator.allowEditSurveyTitle);
    const style: any = {};
    if (!!survey.width) {
      style.maxWidth = survey.renderedWidth;
    }

    return (<React.Fragment>
      <div className={this.model.designerCss} style={style}>
        {surveyHeader}
        {/* <SurveyNavigation survey={survey} location="top" /> */}
        {this.getRenderedPages()}
        {/* <SurveyNavigation
          survey={survey}
          location="bottom"
          css={survey.css}
        /> */}
      </div>
      {this.creator.showPageNavigator ?
        <div className="svc-tab-designer__page-navigator"><SurveyPageNavigator
          pagesController={this.model.pagesController} pageEditMode={this.model.creator.pageEditMode}
        ></SurveyPageNavigator></div>
        : null
      }
    </React.Fragment>);
  }
}

ReactElementFactory.Instance.registerElement("svc-tab-designer", (props) => {
  return React.createElement(
    TabDesignerComponent,
    props as ITabDesignerComponentProps
  );
});
