import { ElementFactory, SvgRegistry } from "survey-core";
import { localization } from "survey-creator-core";
import { ReactQuestionFactory, SurveyQuestionElementBase } from "survey-react-ui";
import { Question } from "survey-core";
import { Serializer } from "survey-core";
import React from "react";


export const PAGE_BREAK_TYPE = "pagebreak";
const locale = localization.getLocale("");
locale.qt[PAGE_BREAK_TYPE] = "Page Break";

class QuestionPageBreakModel extends Question {
    constructor(props: any) {
        super(props);
        this.titleLocation = "hidden";
    }

    getType() {
        return PAGE_BREAK_TYPE;
    }
}

class SurveyQuestionPageBreak extends SurveyQuestionElementBase {
    constructor(props: any) {
        super(props);
    }

    get question() {
        return this.questionBase;
    }

    renderElement() {
        return <hr />;
    }
}


export function registerPageBreak() {
    ElementFactory.Instance.registerElement(PAGE_BREAK_TYPE, (name) => {
        return new QuestionPageBreakModel(name);
    });
    Serializer.addClass(PAGE_BREAK_TYPE, [], () => new QuestionPageBreakModel(""), "question");
    ReactQuestionFactory.Instance.registerQuestion(PAGE_BREAK_TYPE, (props: any) => React.createElement(SurveyQuestionPageBreak, props));
}

SvgRegistry.registerIconFromSvg(
    PAGE_BREAK_TYPE,
    `<svg viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" enable-background="new 0 0 76.00 76.00" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#000000" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 56,35L 56,7.97718C 54.7178,7.20679 53.3822,6.5162 52,5.91232L 52,31L 24,31L 24,5.91231C 22.6178,6.5162 21.2822,7.20678 20,7.97718L 20,35L 56,35 Z M 23,39L 23,41L 25,41L 25,39L 23,39 Z M 27,39L 27,41L 29,41L 29,39L 27,39 Z M 31,39L 31,41L 33,41L 33,39L 31,39 Z M 35,39L 35,41L 37,41L 37,39L 35,39 Z M 39,39L 39,41L 41,41L 41,39L 39,39 Z M 47,39L 47,41L 49,41L 49,39L 47,39 Z M 51,39L 51,41L 53,41L 53,39L 51,39 Z M 20,44L 20,68.0228C 21.2822,68.7932 22.6178,69.4838 24,70.0877L 24,48L 52,48L 52,70.0877C 53.3821,69.4838 54.7178,68.7932 56,68.0228L 56,44L 20,44 Z M 21,39L 19,39L 19,41L 21,41L 21,39 Z M 57,39L 55,39L 55,41L 57,41L 57,39 Z M 43,39L 43,41L 45,41L 45,39L 43,39 Z M 17,39L 15,39L 15,41L 17,41L 17,39 Z M 61,39L 59,39L 59,41L 61,41L 61,39 Z "></path> </g></svg>`
  );