import { Helpers, SurveyModel } from "survey-core";
import { KeyValueArray } from "../types/survey.types";
import { cloneDeep } from 'lodash';

export const savePositionItem = (survey: SurveyModel) => {
  let matrixQuestions = survey
    .getAllQuestions()
    .filter((q: { getType: () => string }) => q.getType() === "matrix");
  matrixQuestions.forEach((matrix) => {
    if (matrix.customRowsOrder === "random") {
      let originalRows = matrix.rows;
      let fixedRows = originalRows.filter(
        (row: { isFixed: boolean }) => row.isFixed
      );
      const fixedRowIndices = fixedRows.map((fixedRow: {rowIndex: number}) => fixedRow.rowIndex);
      let nonFixedRows = originalRows.filter((row: {isFixed: boolean}) => !row.isFixed);
      const randomizedNonFixedRows = Helpers.randomizeArray(nonFixedRows);
      // Combine the fixed and randomized non-fixed rows to get the final array
      const finalRandomizedArray = [];
      for (let i = 0, j = 0, k = 0; i < originalRows.length; i++) {
        if (fixedRowIndices.includes(i)) {
          // Add the fixed row in its original position
          finalRandomizedArray.push(fixedRows[j]);
          j++;
        } else {
          // Add the randomized non-fixed row
          finalRandomizedArray.push(randomizedNonFixedRows[k]);
          k++;
        }
      }
      matrix.rows = finalRandomizedArray;
    }
  });
};

export const getCompleteButton = () => document.querySelector('.sd-navigation__complete-btn') as any;

export const getNextPageButton = () => document.querySelector('.sd-navigation__next-btn') as any;

export const getNextPagePreviewButton = () => document.querySelector('.sd-navigation__preview-btn') as any;

export const disableButton = (button: any) => button?.setAttribute("disabled", "");

export const enableButton = (button: any) => button?.removeAttribute("disabled");

export const disableNextPageButtonOrCompleted = () => {
  disableButton(getNextPageButton());
  disableButton(getCompleteButton());
  disableButton(getNextPagePreviewButton());
};

export const enableNextPageButtonOrCompleted = () => {
  enableButton(getNextPageButton());
  enableButton(getCompleteButton());
  enableButton(getNextPagePreviewButton());
};

export function insertAfter(referenceNode: any, newNode: any) {
  referenceNode.parentNode.parentNode.appendChild(newNode, referenceNode.nextSibling);
}

export function checkAndUpdateValues(obj: Record<string, any>, counter: KeyValueArray[]) {
  counter.forEach(([key, expectedValue]) => {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== expectedValue) {
        return;
      } else {
        obj[key] = 0;
      }
    } else {
      obj[key] = 0;
    }
  });
}

export const removeAnswer = (survey: any, key: string): void => {
  const data = cloneDeep(survey?.data);

  delete data[key];

  survey.data = data;
};