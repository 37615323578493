import { Select } from "antd";
import { OptionItem } from '../types/form.types';
const { Option } = Select;

export const arrayToSelectOptions = (options: OptionItem[]): any => options.map(option => (<Option value={option.value}>{option.label}</Option>));

export const arrayToOptions = (array: any[], mapLabelFn?: (value: any) => string, mapValueFn?: (value: any) => string): OptionItem[] => {
  return array.map((item: any) => ({
    label: mapLabelFn ? mapLabelFn(item) : item,
    value: mapValueFn ? mapValueFn(item) : item,
  }));
};

export const isHidden = (condition: boolean, isInlineBlock: boolean = false): any => condition ? { display: "none" } : { display: isInlineBlock ? "inline-block" : "block" };

export const findOption = (options: OptionItem[], value: string): OptionItem | undefined => options.find((item: any) => item.value === value);
