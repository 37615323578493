import { SurveyTrigger, HashTable } from "survey-core";

class IncrementCounterTrigger extends SurveyTrigger {
  static triggerName = "incrementcountertrigger";

  getType(): string {
    return IncrementCounterTrigger.triggerName;
  }
  public get targetCounter(): string {
    return this.getPropertyValue("targetCounter", "");
  }
  public set targetCounter(val: string) {
    this.setPropertyValue("targetCounter", val);
  }
  public get initialNumber(): string {
    return this.getPropertyValue("initialNumber", "");
  }
  public set initialNumber(val: string) {
    this.setPropertyValue("initialNumber", val);
  }
  protected onSuccess(
    values: HashTable<any>,
    properties: HashTable<any>
  ): void {
    if (
      !this.owner ||
      !this.targetCounter ||
      //@ts-ignore
      this.owner.getQuestionByName(this.targetCounter).getType() != "counter"
    )
      return;
    if (this.isRealExecution()) {
      //@ts-ignore
      let resValue: number = this.initialNumber;
      //@ts-ignore
      let currentValue = this.owner.getValue(this.targetCounter);
      if (currentValue !== undefined) {
        currentValue = Number(currentValue);
        if (currentValue < resValue) {
          resValue++;
        } else {
          resValue = currentValue + 1;
        }
      } else {
        resValue++;
      }
      //@ts-ignore
      this.owner.setValue(this.targetCounter, resValue);
    }
  }
}


export default IncrementCounterTrigger;
