export enum FlowBlockTypes {
    EndSurvey = "endSurvey",
    Section = "section",
    Step = "step",
    SetIdentifier = "setIdentifier",
    IncrementCounter = "incrementCounter",
  }
  
  export enum FlowBlockActions {
    AddPage = "add-page",
    AddBranch = "add-branch",
    Update = "update",
    Delete = "delete",
    AddIdentifier = 'add-identifier',
  }