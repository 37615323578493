import { INIT_DATA_TYPE } from "../custom-questions/init-data";
import { NO_UI_SLIDER_TYPE } from "../custom-questions/nouislider";
import { CONSTANT_SUM_TYPE } from "../widget/ConstantSum/ConstantSum.widget";
import { COUNTER_TYPE } from "../widget/CounterQuestion";
import { QUOTA_TYPE } from "../widget/QuotaQuestion";
import { RANKING_IMAGE_TYPE } from "../widget/RankingImage/RankingImage.widget";
import { ReCAPTCHA_TYPE } from "../widget/ReCAPTCHA";

export enum QuestionType {
  text = "text", // Single-Line Text Input
  comment = "comment", // Long Text
  multipletext = "multipletext", // Multiple Textboxes

  radiogroup = "radiogroup", // Radio Button Group
  checkbox = "checkbox", // Check Box Group
  dropdown = "dropdown", // Dropdown
  tagbox = "tagbox", // Multi-Select Dropdown
  boolean = "boolean", // Yes/No
  rating = "rating", // Rating Scale
  imagepicker = "imagepicker", // Image Picker
  ranking = "ranking", // Ranking

  matrix = "matrix", // Single-Select Matrix
  matrixdropdown = "matrixdropdown", // Multi-Select Matrix
  
  image = "image", // Image
  html = "html", // html
  expression = "expression", // expression
  panel = "panel",
  paneldynamic = "paneldynamic",
  pagebreak = "pagebreak",

  // Custom Types
  rankingImage = RANKING_IMAGE_TYPE,

  counter = COUNTER_TYPE,
  quota = QUOTA_TYPE,
  initdata = INIT_DATA_TYPE,

  nouislider = NO_UI_SLIDER_TYPE,
  constantSum = CONSTANT_SUM_TYPE,

  captcha = ReCAPTCHA_TYPE,
}

export enum QuestionInputType {
  color = "color",
  date = "date",
  datetime = "datetime-local",
  email = "email",
  month = "month",
  number = "number",
  password = "password",
  range = "range",
  tel = "tel",
  text = "text",
  time = "time",
  url = "url",
  week = "week",
}
