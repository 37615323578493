import { Serializer } from "survey-core";


["matrix", "matrixdropdown"].forEach((type) => {
    Serializer.addProperty(type, {
        name: 'copyChoicesAsRowsFrom',
        category: 'rows',
        type: 'dropdown',
        visibleIndex: 0,
        displayName: 'Copy choices from the following question as rows',
        choices: function (obj: any) {
            const allQuestions = obj.survey.getAllQuestions().filter((question: any) => question.getType() === 'checkbox')
                .map((question: any) => ({
                    value: question.name,
                    text: question.name,
                }));
            return [{ value: '', text: 'Copy choices from the following question' }, ...allQuestions];
        },
        onSetValue: function (surveyElement: any, value: any) {
            surveyElement.rows = value ? [] : ['Row 1', 'Row 2'];
            surveyElement.setPropertyValue("copyChoicesAsRowsFrom", value);
            surveyElement.setPropertyValue("isMessagePanelVisible", value ? true : false);
        }
    });

    Serializer.addProperty(type, {
        name: 'choicesFromQuestionMode',
        category: 'rows',
        visibleIndex: 1,
        choices: ['all', 'selected', 'unselected'],
        default: 'all',
        visibleIf: (obj: any) => obj?.selectChoicesFromQuestion ? true : false
    });
});

["radiogroup", "checkbox"].forEach((type) => {
    Serializer.addProperty(type, {
        name: 'selectChoicesFromQuestion',
        category: 'choices',
        type: 'dropdown',
        choices: function (obj: any) {
            const currentQuestionName = obj.name;
            const allQuestions = obj.survey.getAllQuestions().filter((question: any) => question.name !== currentQuestionName)
                .map((question: any) => ({
                    value: question.name,
                    text: question.name,
                }));
            return [{value: '', text: 'Copy choices from the following question'}, ...allQuestions];

        },
        onSetValue: function (surveyElement: any, value: any) {
            surveyElement.choices = [];
            surveyElement.setPropertyValue("selectChoicesFromQuestion", value);
            surveyElement.setPropertyValue("isMessagePanelVisible", value ? true : false);
        },
        visibleIndex: 0
    });
});

Serializer.addProperty("itemvalue", {
    name: "acceptText",
    displayName: "Accept Text",
    type: "boolean",
    default: false,
    onSetValue: function (surveyElement: any, value: boolean) {
        surveyElement.setPropertyValue("acceptText", value);
    },
});

Serializer.addProperty("itemvalue", {
    name: "isFixed",
    displayName: " Fixed",
    type: "boolean",
    default: false,
    onSetValue: function (surveyElement: any, value: boolean) {
        surveyElement.setPropertyValue("isFixed", value);
    },
});

Serializer.addProperty("itemvalue", {
    name: "exclusive",
    displayName: " Exclusive",
    type: "boolean",
    default: false,
    onSetValue: function (surveyElement: any, value: boolean) {
        surveyElement.setPropertyValue("exclusive", value);
    },
});

export { };