import { action, computed, makeObservable, observable, toJS } from "mobx";

export class SurveyWithPageBreaksStore {
  surveyPb: any;
  surveyMap: any;
  surveyPageIndex: number = 0;

  constructor() {
    makeObservable(this, {
      surveyPb: observable,
      surveyMap: observable,
      surveyPageIndex: observable,
    });
  }

  handleSurveyPb = (survey: any) => {
    this.surveyPb = survey;
  };

  handleSurveyMap = (originalToNewPagesMap: any) => {
    this.surveyMap = originalToNewPagesMap;
  };

  handleSurveyPageIndex = (index: number) => {
    this.surveyPageIndex = index;
  };

  get getSurveyPb() {
    return this.surveyPb;
  }

  get getSurveyMap() {
    return this.surveyMap;
  }

  get getSurveyPageIndex() {
    return this.surveyPageIndex;
  }
}

export const surveyWithPageBreaksStore = new SurveyWithPageBreaksStore();
