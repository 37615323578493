import { cloneDeep, isEqual } from 'lodash';
import { Block, Direction, FlowBlockActionsGeneral, FlowBlockTypes } from "../SurveyTaker/types/FlowBlockTypes";
import { getFlowEngineVersion } from "./flow-engine-version.utils";

export function moveNode(nodeId: string, direction: Direction, originalBlocks: Block[]): Block[] {
  // Clone the tree to avoid mutating the original structure
  const blocks = cloneDeep(originalBlocks);
  _moveNode(blocks, nodeId, direction);

  return blocks;
}

export function _moveNode(tree: Block[], nodeId: string, direction: Direction): void {
  let found = false;

  const traverse = (nodes: Block[]) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === nodeId) {
        if (direction === Direction.Up && i > 0) {
          // Move up by swapping with the previous sibling
          const node = cloneDeep(nodes.splice(i, 1)[0]); // Remove the node
          nodes.splice(i - 1, 0, node); // Insert it before the previous node
                    
          found = true;
        } else if (direction === Direction.Down && i < nodes.length - 1) {
          // Move down by swapping with the next sibling
          const node = cloneDeep(nodes.splice(i, 1)[0]); // Remove the node
          nodes.splice(i + 1, 0, node); // Insert it after the next node (which is now at current index)
                    
          found = true;
        }
        break;
      }
      if (nodes[i].actions) {
        traverse(nodes[i].actions);
        if (found) break;
      }
    }
  };

  traverse(tree);
}

export function insertBlockBefore(nodeId: string, newNode: Block, originalBlocks: Block[]): Block[] {
  const traverseAndInsert = (nodes: Block[], newNodeInserted: boolean = false): boolean => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === nodeId) {
        // Insert the new node after the current node
        nodes.splice(i, 0, newNode);
        return true; // Node inserted successfully
      }
      // If the node has children, traverse them
      if (nodes[i].actions) {
        newNodeInserted = traverseAndInsert(nodes[i].actions, newNodeInserted);
        if (newNodeInserted) break; // Stop if the new node has been inserted
      }
    }
    return newNodeInserted;
  };

  // Clone the tree to avoid mutating the original structure
  const blocks = cloneDeep(originalBlocks);
  traverseAndInsert(blocks);

  return blocks;
}
export function insertBlockAfter(nodeId: string, newNode: Block, originalBlocks: Block[]): Block[] {
  const traverseAndInsert = (nodes: Block[], newNodeInserted: boolean = false): boolean => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === nodeId) {
        // Insert the new node after the current node
        nodes.splice(i + 1, 0, newNode);
        return true; // Node inserted successfully
      }
      // If the node has children, traverse them
      if (nodes[i].actions) {
        newNodeInserted = traverseAndInsert(nodes[i].actions, newNodeInserted);
        if (newNodeInserted) break; // Stop if the new node has been inserted
      }
    }
    return newNodeInserted;
  };

  // Clone the tree to avoid mutating the original structure
  const blocks = cloneDeep(originalBlocks);
  traverseAndInsert(blocks);

  return blocks;
}

export function addBlock(block: Block, originalBlocks: Block[]): Block[] {
  const blocks = cloneDeep(originalBlocks);

  _addBlock(block, blocks);

  return blocks;
}

export function _addBlock(block: Block, blocks: Block[]): void {
  blocks.push(block);
}

export function addChildToBlock(parentId: string, childBlock: Block, originalBlocks: Block[]): Block[] {
  const blocks = cloneDeep(originalBlocks);

  _addChildToBlock(parentId, childBlock, blocks);

  return blocks;
}

export function _addChildToBlock(parentId: string, childBlock: Block, blocks: Block[]): boolean {
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].id === parentId) {
      if (blocks[i].actions) {
        blocks[i].actions.push(childBlock);
      } else {
        blocks[i].actions = [childBlock];
      }
      return true; // Child added successfully
    }

    // Recursively search in children
    if (blocks[i].actions?.length > 0) {
      const childAdded = _addChildToBlock(parentId, childBlock, blocks[i].actions);
      if (childAdded) return true;
    }
  }
  return false; // Parent block not found
}

export function moveEndSurveyToLast(blocks: Block[]): Block[] {
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].actions?.length > 0) {
      // Recursively rearrange children
      blocks[i].actions = moveEndSurveyToLast(blocks[i].actions);

      // Move "endSurvey" blocks to the end
      blocks[i].actions.sort((a: Block, b: Block) => (a.type === FlowBlockTypes.EndSurvey) ? 1 : (b.type === FlowBlockTypes.EndSurvey) ? -1 : 0);
    }
  }
  return blocks;
}

export function addIdentifiers(nodes: Block[]): Block[] {
  const tree: Block[] = cloneDeep(nodes);

  function traverse(node: Block) {
    if (node.type === FlowBlockTypes.EndSurvey) {
      if (node.identifiers?.length > 0) {
        const parent = getParentBlockByChildId(node.id, tree);

        parent.actions.splice(parent.actions.length - 1, 0, ...node.identifiers);
      }
    }

    if (node.actions) {
      node.actions.forEach((child: Block) => traverse(child)); // Recursively traverse children
    }
  }

  tree.forEach(node => traverse(node)); // Start the traversal for each root node

  return tree;
}

export function addBlockAfterTypes(
  insertBlocks: Block,
  blocks: Block[],
  types: string[]
): Block[] {
  const result: Block[] = [];

  for (let i = 0; i < blocks.length; i++) {
    result.push(blocks[i]);

    if (types.includes(blocks[i].type)) {
      result.push(...insertBlocks);
    }

    // Recursively process nested actions
    if (blocks[i].actions && blocks[i].actions.length > 0) {
      blocks[i].actions = addBlockAfterTypes(insertBlocks, blocks[i].actions, types);
    }
  }

  return cloneDeep(result);
}

export function updateBlock(blockId: string, newProps: any, originalBlocks: Block[]): Block[] {
  const blocks = cloneDeep(originalBlocks);

  _updateBlock(blockId, newProps, blocks);

  return blocks;
}

export function _updateBlock(blockId: string, newProps: any, blocks: Block[]): boolean {
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].id === blockId) {
      // Update properties of the block
      blocks[i] = { ...blocks[i], ...newProps };
      return true; // Block updated successfully
    }

    // Recursively search in children
    if (blocks[i].actions?.length > 0) {
      const updated = _updateBlock(blockId, newProps, blocks[i].actions);
      if (updated) return true;
    }
  }
  return false; // Block not found
}

export function getBlockById(blockId: string, blocks: Block[]): Block | null {
  for (const block of blocks) {
    if (block.id === blockId) {
      return cloneDeep(block); // Found the block
    }

    // Recursively search in children
    if (block.actions?.length > 0) {
      const foundBlock = cloneDeep(getBlockById(blockId, block.actions));

      if (foundBlock) {
        delete foundBlock.component;

        return foundBlock;
      };
    }
  }
  return null; // Block not found
}

export function getBlockByKey(blockKey: string, blocks: Block[]): Block | null {
  for (const block of blocks) {
    if (block.key === blockKey) {
      return block; // Found the block
    }

    // Recursively search in children
    if (block.actions?.length > 0) {
      const foundBlock = getBlockById(blockKey, block.actions);

      if (foundBlock) {
        return foundBlock;
      };
    }
  }
  return null; // Block not found
}

export function getParentBlockByChildId(blockId: string, blocks: Block[], parent: Block | null = null): Block | null {
  for (const block of blocks) {
    if (block.actions?.length > 0) {
      if (block.actions.some((child: Block) => child.id === blockId)) {
        return block; // Found the parent block
      }

      // Recursively search in children
      const foundParent = getParentBlockByChildId(blockId, block.actions, block);

      if (foundParent) {
        delete foundParent.component;

        return foundParent;
      }
    }
  }
  return null; // Parent block not found
}

export function findRootBlockByChildId(id: string, blocks: Block[], root: Block | null = null): Block | null {
  for (const block of blocks) {
    const currentRoot = root || block;

    if (block.actions?.some((child: Block) => child.id === id)) {
      return currentRoot; // Found the root block
    }

    // Recursively search in children
    if (block.actions?.length > 0) {
      const foundRoot = findRootBlockByChildId(id, block.actions, currentRoot);

      if (foundRoot) {
        return foundRoot;
      }
    }
  }
  return null; // Root block not found
}

export function getBlockBySectionName(sectionName: string, blocks: Block[]): Block | null {
  for (const block of blocks) {
    if (block.page === sectionName) {
      return cloneDeep(block); // Found the block
    }

    // Recursively search in children
    if (block.actions?.length > 0) {
      const foundBlock = cloneDeep(getBlockBySectionName(sectionName, block.actions));

      if (foundBlock) {
        delete foundBlock.component;

        return foundBlock;
      };
    }
  }
  return null; // Block not found
}

export function removeAllBlocksBySectionName(sectionName: string, blocks: Block[]): Block[] {
  while (getBlockBySectionName(sectionName, blocks)) {
    const block = getBlockBySectionName(sectionName, blocks);

    if (block) {
      blocks = deleteBlockById(block.id, blocks);
    }
  }

  return blocks;
}

export function deleteBlockById(blockId: string, originalBlocks: Block[]): Block[] {
  const blocks = cloneDeep(originalBlocks);

  _deleteBlockById(blockId, blocks);

  return blocks;
}

export function _deleteBlockById(blockId: string, blocks: Block[]): boolean {
  for (let i = 0; i < blocks?.length; i++) {
    if (blocks[i].id === blockId) {
      blocks.splice(i, 1); // Remove the block
      return true; // Block deleted successfully
    }

    // Recursively search in children
    if (blocks[i].actions?.length > 0) {
      const deleted = _deleteBlockById(blockId, blocks[i].actions);
      if (deleted) return true;
    }
  }

  return false; // Block not found
}

export const isAddAction = (action: FlowBlockActionsGeneral): boolean => [FlowBlockActionsGeneral.AddAfter, FlowBlockActionsGeneral.AddBefore, FlowBlockActionsGeneral.AddChild].includes(action);

export function getAllKeys(tree: Block[]): string[] {
  let keys: string[] = [];

  function traverse(node: Block) {
    if (node.actions) {
      keys.push(node.key); // Add the current node's id

      node.actions.forEach((child: Block) => traverse(child)); // Recursively traverse children
    }
  }

  tree.forEach(node => traverse(node)); // Start the traversal for each root node

  return keys;
}

export function getAllSectionNames(tree: Block[]): string[] {
  let keys: string[] = [];

  function traverse(node: Block) {
    if (node.type === FlowBlockTypes.Section) {
      if (keys.indexOf(node.page) === -1) {
        keys.push(node.page); // Add the current node's id
      }
    }

    if (node.actions) {
      node.actions.forEach((child: Block) => traverse(child)); // Recursively traverse children
    }
  }

  tree.forEach(node => traverse(node)); // Start the traversal for each root node

  return keys;
}

export function treeToFlowEngine(originalBlocks: Block[]): Block[] {
  const blocks = cloneDeep(originalBlocks);

  _removeSpecificBlockProperties(blocks);

  return blocks;
}

export function _removeSpecificBlockProperties(blocks: Block[]): void {
  for (let i = 0; i < blocks.length; i++) {
    if ([FlowBlockTypes.Title, FlowBlockTypes.Separator, FlowBlockTypes.AddItem].includes(blocks[i].type)) {
      // Remove the title block
      blocks.splice(i, 1);
      i--; // Decrement the index to account for the removed element
      continue;
    }

    if (blocks[i].id) {
      // Update properties of the block
      delete blocks[i].component;
      delete blocks[i].key;
      delete blocks[i].title;

      if (blocks[i].type === FlowBlockTypes.Section) {
        delete blocks[i].actions;
      }
    }

    // Recursively search in children
    if (blocks[i].actions?.length > 0) {
      _removeSpecificBlockProperties(blocks[i].actions);
    }
  }
}

export function mapKeysAndIds(tree: Block[]): { [id: string]: string } {
  const map: { [id: string]: string } = {};

  function traverse(node: Block) {
    if (node.key && node.id) {
      map[node.id] = node.key;
    }

    if (node.actions) {
      node.actions.forEach((child: Block) => traverse(child)); // Recursively traverse children
    }
  }

  tree.forEach(node => traverse(node)); // Start the traversal for each root node

  return map;
}