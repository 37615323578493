import React from "react";
import { Question, ElementFactory, Serializer, SvgRegistry } from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from "survey-react-ui";
import { localization } from "survey-creator-core";

import Nouislider from "nouislider-react";

import "nouislider/distribute/nouislider.css";

export const NO_UI_SLIDER_TYPE = "nouislider";

const locale = localization.getLocale("");
locale.qt[NO_UI_SLIDER_TYPE] = "No UI Slider";

export class NoUiSliderModel extends Question {
  constructor(name) {
    super(name);
  }

  getType() {
    return NO_UI_SLIDER_TYPE;
  }

  get step() {
    return this.getPropertyValue("step");
  }
  set step(value) {
    this.setPropertyValue("step", value);
  }

  get rangeMin() {
    return this.getPropertyValue("rangeMin");
  }
  set rangeMin(value) {
    this.setPropertyValue("rangeMin", value);
  }

  get rangeMax() {
    return this.getPropertyValue("rangeMax");
  }
  set rangeMax(value) {
    this.setPropertyValue("rangeMax", value);
  }

  get pipsMode() {
    return this.getPropertyValue("pipsMode");
  }
  set pipsMode(value) {
    this.setPropertyValue("pipsMode", value);
  }

  get pipsValues() {
    return this.getPropertyValue("pipsValues");
  }
  set pipsValues(value) {
    this.setPropertyValue("pipsValues", value);
  }

  get pipsText() {
    return this.getPropertyValue("pipsText");
  }
  set pipsText(value) {
    this.setPropertyValue("pipsText", value);
  }

  get pipsDensity() {
    return this.getPropertyValue("pipsDensity");
  }
  set pipsDensity(value) {
    this.setPropertyValue("pipsDensity", value);
  }

  get orientation() {
    return this.getPropertyValue("orientation");
  }
  set orientation(value) {
    this.setPropertyValue("orientation", value);
  }

  get direction() {
    return this.getPropertyValue("direction");
  }
  set direction(value) {
    this.setPropertyValue("direction", value);
  }

  get tooltips() {
    return this.getPropertyValue("tooltips");
  }
  set tooltips(value) {
    this.setPropertyValue("tooltips", value);
  }
}

class SurveyQuestionNoUiSlider extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    const pipeValuesArray = this.questionBase.getPropertyValue("pipsValues") || [];

    const pipeTextArray = this.questionBase.getPropertyValue("pipsText") || [];
    const orientation = this.questionBase.getPropertyValue("orientation");
    const density = this.questionBase.getPropertyValue("pipsDensity");
    const direction = this.questionBase.getPropertyValue("direction");
    const tooltips = this.questionBase.getPropertyValue("tooltips");
    const rangeMin = this.questionBase.getPropertyValue("rangeMin");
    const rangeMax = this.questionBase.getPropertyValue("rangeMax");
    const mode = this.questionBase.getPropertyValue("pipsMode");
    const step = this.questionBase.getPropertyValue("step");
    const value = this.questionBase.value;
    const start = rangeMin <= value && value <= rangeMax ? value : Math.floor(rangeMin + rangeMax / 2);
    const pipeValues = pipeValuesArray.map((pVal) => parseInt(pVal.value));
    !this.questionBase.value && (this.questionBase.value = start);
    let pips = null;
    if (pipeValuesArray.length) {
      pips = {
        mode: mode,
        density: density,
        values: pipeValues,
        format: {
          to: function (value) {
            let pipText = value;
            pipeTextArray.map(function (el) {
              if (el.text !== undefined && value === parseInt(el.value)) {
                pipText = el.text;
              }
            });
            return pipText;
          },
        },
      };
    }

    this.state = {
      start: start,
      orientation,
      step,
      rangeMin,
      rangeMax,
      direction,
      tooltips,
      pips: pips,
    };
  }
  get question() {
    return this.questionBase;
  }

  onUpdate = (value) => {
    this.questionBase.value = Math.floor(value[0]);
  };

  renderElement() {
    return (
      <div
        style={{
          paddingTop: "44px",
          paddingRight: "20px",
          paddingBottom: "19px",
        }}
      >
        <Nouislider
          style={
            this.state.orientation === "vertical"
              ? { marginBottom: "60px", height: "250px", marginLeft: "50%" }
              : { marginBottom: '20px'}
          }
          step={this.state.step}
          orientation={this.state.orientation}
          direction={this.state.direction}
          tooltips={this.state.tooltips}
          range={{ min: this.state.rangeMin, max: this.state.rangeMax }}
          start={this.state.start} //calulate dynamically
          connect={[true, false]}
          pips={this.state.pips}
          onSlide={this.onUpdate.bind(this)}
          ref="NoUiSlider"
        />
      </div>
    );
  }
}

function init() {
  ElementFactory.Instance.registerElement(NO_UI_SLIDER_TYPE, (name) => {
    return new NoUiSliderModel(name);
  });
  //Register Question Class
  Serializer.addClass(
    NO_UI_SLIDER_TYPE,
    [
      {
        name: "step:number",
        category: "slider",
        categoryIndex: 1,
        default: 1,
      },
      {
        name: "rangeMin:number",
        category: "slider",
        default: 0,
      },
      {
        name: "rangeMax:number",
        category: "slider",
        default: 100,
      },
      {
        name: "pipsMode",
        category: "slider",
        default: "positions",
        choices: ["positions", "values", "range"],
      },
      {
        name: "orientation",
        category: "slider",
        default: "horizontal",
        choices: ["horizontal", "vertical"],
      },
      {
        name: "direction:string",
        category: "slider",
        default: "ltr",
        choices: ["ltr", "rtl"],
      },
      {
        name: "tooltips:boolean",
        category: "slider",
        default: true,
      },
      {
        name: "pipsDensity:number",
        category: "slider",
        default: 5,
      }
    ],
    () => new NoUiSliderModel(""),
    "question"
  );

  Serializer.addProperties(NO_UI_SLIDER_TYPE, [
    {
      name: "pipsValues:itemvalues",
      category: "slider",
    },
    {
      name: "pipsText:itemvalues",
      category: "slider",
    },
  ]);

  ReactQuestionFactory.Instance.registerQuestion(
    NO_UI_SLIDER_TYPE,
    (props) => {
      return React.createElement(SurveyQuestionNoUiSlider, props);
    }
  );
}

SvgRegistry.registerIconFromSvg(
  NO_UI_SLIDER_TYPE,
  `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <rect y="10" width="16" height="2"/>
    <polygon points="2,4 2,7 4,9 6,7 6,4 "/>
    <polygon points="10,4 10,7 12,9 14,7 14,4 "/>
    </svg>`
);
export default init;
