import { INIT_DATA_TYPE } from "../../survey-js-extensions/custom-questions/init-data";
import { C_VAR, H_VAR, IdentifierTypes, CUSTOM_VARIABLE, Variable, VariableType, VariablesObject, cVariableOptionItems, hVariableOptionItems, CUSTOM_VARIABLE_LABEL, hVariableSettableOptionItems, C_VAR_LABEL, HVariable, H_VAR_LABEL, QUOTA, QUOTA_LABEL, Quota, VariablePages, VariablesDefaults, identifierTypes, IdentifierFormItem } from "../../survey-js-extensions/types/variables.types";
import { v4 as uuidv4 } from "uuid";
import { OptionItem } from "../types/form.types";
import { Block, FlowBlockTypes } from "../../components/SurveyTaker/types/FlowBlockTypes";
import { ExpressionType } from "../types/expression-builder.types";
import { QuestionType } from "../types/question.types";
import { findOption } from "./form.utils";
import { isEmptyCustom } from "./expression-builder.utils";
import { isEmpty } from "lodash";
import { TakerCounter, TakerSurveyData } from "../../components/SurveyTaker/types/survey.types";
import { id } from "./utils";
import { addBlockAfterTypes } from "../../components/FlowEngine/flow-engine-crud.utils";

export const variablePageToType = (page: VariablePages): VariableType => {
  if (page === VariablePages.ViewUrlVariables) {
    return VariableType.UrlParam;
  } else if (page === VariablePages.ViewCounterVariables) {
    return VariableType.Counter;
  } else if (page === VariablePages.ViewCustomVariables) {
    return VariableType.Value;
  } else if (page === VariablePages.ViewSystemVariables) {
    return VariableType.System;
  }

  return VariableType.Value;
};

export const variableTypeToPage = (type: VariableType): VariablePages => {
  if (type === VariableType.UrlParam) {
    return VariablePages.ViewUrlVariables;
  } else if (type === VariableType.Counter) {
    return VariablePages.ViewCounterVariables;
  } else if (type === VariableType.Value) {
    return VariablePages.ViewCustomVariables;
  } else if (type === VariableType.System) {
    return VariablePages.ViewSystemVariables;
  }

  return VariablePages.ChooseVariable;
};

export const filterVariablesByType = (variables: Variable[] = [], type: VariableType): Variable[] => {
  return variables?.filter((variable: Variable) => variable.type === type);
};

export const getSystemVariables = (): any[] => {
  return Object.keys(VariablesDefaults).map((name: string) => ({
    name,
    defaultValue: JSON.stringify(VariablesDefaults[name as HVariable]),
  }));
};

export const mapVariablesToOptionItems = (variables: Array<Variable | Quota> = []): OptionItem[] => {
  return variables?.map((item: Variable | Quota) => {
    return {
      label: `${item.name}`,
      value: item.name,
    };
  });
};

export const mapVariablesToQuestionObjects = (variableManager: VariablesObject): object[] => {
  const variables = variableManager?.variables?.map((variable: Variable) => {
    const base = {
      name: variable.name,
      title: variable.name,
      defaultValue: "",
    };
    const specific: any = {};

    switch (variable.type) {
      case VariableType.Counter:
        specific.type = QuestionType.counter;
        break;
    
      case VariableType.UrlParam:
        specific.type = INIT_DATA_TYPE;
        specific.dataType = "QueryString";
        specific.queryString = variable.name;
        break;
    
      case VariableType.Value:
        specific.type = QuestionType.text;
        break;
    
      default:
        break;
    }

    return {...base, ...specific};
  });

  const quotas = variableManager?.quotas?.map((quota: Quota) => {
    return {
      name: quota.name,
      title: quota.name,
      type: QuestionType.quota,
      defaultValue: 0,
    };
  }) || [];

  const defaultQuestion = {
    name: "___initial_hidden_question",
    type: QuestionType.text,
    title: "",
    defaultValue: "",
  };

  return [defaultQuestion, ...variables, ...quotas];
};

export const mapOverrideToSetIdentifier = (overrides: IdentifierFormItem[]): Block => {
  const setIdentifier = {
    id: uuidv4(),
    type: FlowBlockTypes.SetIdentifier,
    items: overrides.map((override: IdentifierFormItem) => override),
  };

  return setIdentifier;
};

export const mapVariablesToFlowEngineObjects = (variables: Variable[]): Block[] => {
  const setIdentifier = {
    id: uuidv4(),
    type: FlowBlockTypes.SetIdentifier,
    items: variables.map((variable: Variable) => {
      return {
        lhs: {
          type: IdentifierTypes.Variable,
          identifier: variable.name,
        },
        valueType: ExpressionType.CustomValue,
        value: isEmptyCustom(variable.defaultValue) ? "" : variable.defaultValue,
      };
    }),
  };

  const section = {
    page: "_variables",
    type: FlowBlockTypes.Section,
    id: uuidv4(),
  };

  // Variables section should always be the first section
  return [section, setIdentifier];
};

export const mapTakerInitDataToFlowEngineObjects = (surveyData: TakerSurveyData): Block[] => {
  const initDataItems: any[] = [];

  Object.keys(surveyData?.initData?.QueryData || {}).forEach((name: string) => {
    initDataItems.push({
      lhs: {
        type: IdentifierTypes.Variable,
        identifier: name,
      },
      valueType: ExpressionType.CustomValue,
      value: surveyData?.initData?.QueryData[name],
    });
  });

  const counterDataItems: any[] = [];

  [...(surveyData?.counters || []), ...(surveyData?.quotaCounters || [])]
  ?.forEach((_counter: TakerCounter) => {
    const counter = _counter[Object.keys(_counter)[0]];

    counterDataItems.push({
      lhs: {
        type: IdentifierTypes.Question,
        identifier: counter?.linkKey,
      },
      valueType: ExpressionType.CustomValue,
      value: counter?.count,
    });
  });

  const setIdentifier = {
    id: uuidv4(),
    type: FlowBlockTypes.SetIdentifier,
    items: [...initDataItems, ...counterDataItems],
  };

  return [setIdentifier];
};

export const isCustomVariable = (name: any): boolean => {
  return name?.startsWith("v_");
};

export const isHVariable = (name: any): boolean => {
  return name?.startsWith("h_");
};

export const isContactsVariable = (name: any): boolean => {
  return name?.startsWith("c_");
};

export const isQuotaVariable = (name: any): boolean => {
  return name?.startsWith("q_");
};

export const getVariableType = (name: any): any => {
  if (!name) {return "";};

  if (isCustomVariable(name)) {
    return CUSTOM_VARIABLE;
  } else if (isHVariable(name)) {
    return H_VAR;
  } else if (isContactsVariable(name)) {
    return C_VAR;
  } else if (isQuotaVariable(name)) {
    return QUOTA;
  }
};

export const getVariableTypeLabel = (name: any): any => {
  const type = getVariableType(name);

  if (!type) {return findOption(identifierTypes, IdentifierTypes.Variable);};

  switch (type) {
    case CUSTOM_VARIABLE:
      return CUSTOM_VARIABLE_LABEL;
      break;
    case H_VAR:
      return H_VAR_LABEL;
      break;
    case C_VAR:
      return C_VAR_LABEL;
      break;
    case QUOTA:
      return QUOTA_LABEL;
      break;
  
    default:
      return findOption(identifierTypes, IdentifierTypes.Variable);
      break;
  }
};

export function addVariablesToSurvey(surveyObj: any, variablesObj: VariablesObject = {variables: [], quotas: []}): any {
  if (variablesObj.variables?.length) {
    surveyObj.pages.push({
      name: "_variables",
      elements: mapVariablesToQuestionObjects(variablesObj),
    });
  }

  return surveyObj;
}

export function insertBeforeFlowEngine(flowEngineObj: any, blocks: Block[]): any {
  if (Array.isArray(blocks)) {
    flowEngineObj[0].actions.unshift(...blocks);
  }

  return flowEngineObj;
}

export function addVariablesToFlowEngine(flowEngineObj: any, surveyData: TakerSurveyData): any {
  if (!isEmpty(surveyData)) {
    flowEngineObj[0].actions.unshift(...mapTakerInitDataToFlowEngineObjects(surveyData));
  }

  if (surveyData?.variables?.length) {
    flowEngineObj[0].actions.unshift(...mapVariablesToFlowEngineObjects(surveyData?.variables || []));
  }

  return flowEngineObj;
}

export function isFlowEngineHasVariables(flow: Block[]): boolean {
  return flow[0]?.actions[0]?.page === "_variables";
}

export function addIncrementQuotaToFlowEngine(flowEngineObj: any, quotas: Quota[] = [], values: any = {}): Block[] {
  const blocks: Block[] = [];

  quotas?.forEach((quota: Quota) => {
    const incrementQuotaBranch: Block = {
      id: id(),
      type: FlowBlockTypes.Step,
      expression: quota.expression,
      actions: [
        {
          id: id(),
          type: FlowBlockTypes.SetIdentifier,
          items: [{
            lhs: {
              type: IdentifierTypes.Question,
              identifier: quota.name,
            },
            valueType: ExpressionType.CustomValue,
            value: +(values[quota.name] || 0) + 1,
          }]
        }
      ]
    };
    
    blocks.push(incrementQuotaBranch);
  });

  return addBlockAfterTypes(blocks, flowEngineObj, [
    FlowBlockTypes.IncrementCounter,
    FlowBlockTypes.SetIdentifier,
    FlowBlockTypes.Section,
  ]);
}

export function countersToObject(counters: TakerCounter[] = []): object {
  const result: any = {};

  counters?.forEach((_counter: TakerCounter) => {
    const counter = _counter[Object.keys(_counter)[0]];

    result[counter.linkKey] = counter.count;
  });

  return result;
}