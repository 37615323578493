import React from "react";
import { ReactElementFactory } from "survey-react-ui";
import { CheckboxItemExtension } from "./checkbox-item-extension";

class RadioGroupItemExtension extends CheckboxItemExtension {
  constructor(props: any) {
    super(props);
  }
}

export const registerRadioGroupItemExtension = (survey: any) => {
  ReactElementFactory.Instance.registerElement("radiogroup-item-ext", (props) => {
    return React.createElement(RadioGroupItemExtension, { ...props, survey: survey });
  });
};