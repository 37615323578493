import { v4 as uuidv4 } from "uuid";
import { surveyWithPageBreaksStore } from "./../../../store/pageBreak_store";
import { cloneDeep } from "lodash";
import _ from "lodash";

export const modifySurveyJsonForPageBreaks = (cachedSurvey: any) => {
  let survey = cachedSurvey;
  let originalToNewPagesMap: any;
  originalToNewPagesMap = {}; // Reset the map
  const pagesCopy = [...survey.pages];
  survey.pages = [];
  pagesCopy.forEach((page) => {
    let newPages = breakPageBasedOnPageBreaks(page);
    if (newPages && newPages.length > 0) {
      // If the page got broken into more pages, add it to our map
      originalToNewPagesMap[page.name] = newPages.map((newPage) => newPage.name);
      // Add the new split pages back to the survey
      survey.pages.push(...newPages);
    } else {
      // If no page break in this page, just add the original page back to the survey
      survey.pages.push(page)
    }
  });
  survey.pages.push({ name: "aethlkejrlkha", elements: [{ type: "checkbox", name: "takldsjfklasjdf" }] });
  setSurveyStoreData(survey, originalToNewPagesMap);
};

export const breakPageBasedOnPageBreaks = (page: any) => {
  
  if (!page || !page?.elements)
  {
    return [];
  }
  
  let elements = cloneDeep(page.elements);

  if ( !elements.some((el: { type: string }) => el.type === "pagebreak")) {
    return [];
  }

  let pages: any[] = [];
  let currentPageElements: any[] = [];
  let currentPageNo = 1;

  let currentPageIndex = 1; // Initialize page index to 1

  elements.forEach((element: { type: string }, index: number) => {
    if (element.type !== "pagebreak" || (index === 0 && element.type !== "pagebreak")) {
      currentPageElements.push(element);
    } else {
      // This ensure we do not create page with zero elements
      if (currentPageElements.length > 0) {
        let newPage = createPageFromOriginal(
          page,
          currentPageElements,
          currentPageIndex
        );
        pages.push(newPage);
        currentPageElements = []; // reset currentPageElements
        currentPageIndex++; // increment the page number
      }
    }
  });

  // This condition is to handle non-pagebreak elements after the last page break
  if (currentPageElements.length > 0) {
    let newPage = createPageFromOriginal(
      page,
      currentPageElements,
      currentPageNo
    );
    pages.push(newPage);
  }

  return pages;
};

export const createPageFromOriginal = (
  originalPage: any,
  elements: any[],
  pageNo: number
) => {
  let newPage = { ...originalPage }; // Creating a deep copy
  newPage.elements = elements; // Assign the sliced elements
  // Creating new unique page name
  newPage.name = `${originalPage.name}_${pageNo}_${uuidv4()}${"_pb"}`;
  return newPage;
};

export const setSurveyStoreData = (survey: any, originalToNewPagesMap: any) => {
  surveyWithPageBreaksStore.handleSurveyPb(survey);
  surveyWithPageBreaksStore.handleSurveyMap(originalToNewPagesMap);
};

export const isSubpage = (pageName: string) => {
  return pageName.includes(`_pb`);
};

export const handleNextPage = (nextPage: any, survey: any) => {
    nextPage.questions.forEach((question: { defaultValue: any; name: any }) => {
      if (question.defaultValue) {
        survey.setValue(question.name, question.defaultValue);
      }
    });
    return false;
};
