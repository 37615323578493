import * as React from "react";
import { ISurveyCreator } from "./reactquestion";
import { SurveyModel, Question, QuestionRowModel, IElement, Base, PanelModel } from "survey-core";
import { SurveyElementBase } from "./reactquestion_element";
import { ReactElementFactory } from "./element-factory";
import { SvgIcon } from "./components/svg-icon/svg-icon";

export class SurveyRowElement extends SurveyElementBase<any, any> {
  private rootRef: React.RefObject<HTMLDivElement>;
  constructor(props: any) {
    super(props);
    (this.element as Question).cssClasses;
    this.rootRef = React.createRef();
  }
  protected getStateElement(): any {
    return this.element;
  }
  private get element(): PanelModel | Question {
    return this.props.element as any;
  }
  private get index(): number {
    return this.props.index;
  }
  private get row(): QuestionRowModel {
    return this.props.row;
  }
  private get survey(): SurveyModel {
    return this.props.survey;
  }
  private get creator(): ISurveyCreator {
    return this.props.creator;
  }
  protected get css(): any {
    return this.props.css;
  }

  componentDidMount(): void {
    super.componentDidMount();
    if(this.rootRef.current) {
      (this.element).setWrapperElement(this.rootRef.current);
    }
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();
    this.element.setWrapperElement(undefined);
  }

  public shouldComponentUpdate(nextProps: any, nextState: any): boolean {
    if (!super.shouldComponentUpdate(nextProps, nextState)) return false;
    if (nextProps.element !== this.element) {
      if(nextProps.element) {
        nextProps.element.setWrapperElement(this.rootRef.current);
      }
      if(this.element) {
        this.element.setWrapperElement(undefined);
      }
    }

    (this.element as Question).cssClasses;
    return true;
  }

  protected toggleBreak(e: any): any {
    e.stopPropagation();
    //@ts-ignore
    this.creator.currentPage = this.element.page;
    let nextIndex = this.row.index + 1;
    //@ts-ignore
    const nextEl = this.creator.currentPage.elements[nextIndex];
    if(nextEl && nextEl.classMetaData.name === "pagebreak") {
      //@ts-ignore
      this.creator.currentPage.removeElement(nextEl);
    } else {
      //@ts-ignore
      this.creator.currentPage.addNewQuestion("pagebreak", `pagebreak_${nextIndex}`, nextIndex);
    }
  }

  protected isExistBreak(): any {
    let nextIndex = this.row.index + 1;
    //@ts-ignore
    if(!this.element.page || !this.element.page.elements) return false;
    const nextEl = this.element.page.elements[nextIndex];
    //@ts-ignore
    return !!nextEl && nextEl.classMetaData.name === "pagebreak";
  }

  protected isElementIsQuestion(): boolean {
    const element = this.element;
    const css = (element as Question).cssClassesValue;
    // @ts-ignore
    return !!css?.mainRoot?.includes("sd-question") && css.flowRoot.includes("sd-question") && element.isQuestionElement && this.props.survey.isDesignMode;
  }

  protected addQuestion(e: any): void {
    e.stopPropagation();
    //@ts-ignore
    this.creator.currentPage = this.element.page;
    // e.stopPropagation();
    let nextIndex = this.row.index + 1;
    //@ts-ignore
    const nextEl = this.creator.currentPage.elements[nextIndex];
    if(nextEl && nextEl.classMetaData.name === "pagebreak") {
      nextIndex = nextIndex + 1;
    }
    //@ts-ignore
    this.creator.currentPage.addNewQuestion("text", null, nextIndex);
  }

  protected renderElement(): JSX.Element {
    const element = this.element;
    const innerElement = this.createElement(element, this.index);
    const css = (element as Question).cssClassesValue;
    const focusIn = () => {
      const el: any = element;
      if (el && el.isQuestion) {
        el.focusIn();
      }
    };

    const typoCss = { color: "#6E6E6E", fontFamily: "Lato", fontSize: 14, fontStyle: "normal", marginLeft: 5, cursor: "pointer" };

    const deleteContainer = { marginLeft: 5, cursor: "pointer" };

    return (
      <div
        className={css.questionWrapper}
        style={(element as any).rootStyle}
        data-key={innerElement.key}
        key={innerElement.key}
        onFocus={focusIn}
        ref={this.rootRef}
      >
        {this.row.isNeedRender ?
          innerElement :
          ReactElementFactory.Instance.createElement(element.skeletonComponentName, { element: element, css: this.css, })}

        {this.isElementIsQuestion() &&
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20, height: 20, alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                <SvgIcon
                  size={16}
                  iconName={"icon-plus"}>
                </SvgIcon>
                <span style={typoCss} onClick={(e) => this.addQuestion(e)}> Add Question</span>
              </div>
              {this.isExistBreak() &&
                  <div style={{ flex: "4", display: "flex", alignItems: "center" }}>
                    <hr style={{ width: "100%", height: 0 }} />
                    <div style={deleteContainer} onClick={(e) => this.toggleBreak(e)}>
                      <SvgIcon
                        size={16}
                        iconName={"icon-remove-page-break"}>
                      </SvgIcon>
                    </div>
                  </div>}
              {!this.isExistBreak() &&
                  <div style={{ display: "flex", alignItems: "center", flex: 3, justifyContent: "end" }}>
                    <SvgIcon
                      size={16}
                      iconName={"icon-page-break"}>
                    </SvgIcon>
                    <span style={typoCss} onClick={(e) => this.toggleBreak(e)}> Add Page Break</span>
                  </div>}
            </div>
        }
      </div>
    );
  }

  protected createElement(element: IElement, elementIndex?: number): JSX.Element {
    const index = elementIndex ? "-" + elementIndex : 0;
    var elementType = element.getType();
    if (!ReactElementFactory.Instance.isElementRegistered(elementType)) {
      elementType = "question";
    }
    return ReactElementFactory.Instance.createElement(elementType, {
      key: element.name + index,
      element: element,
      creator: this.creator,
      survey: this.survey,
      css: this.css,
    });
  }
}
