import { createElement } from "react";
import {
  ElementFactory,
  Serializer,
  QuestionMatrixDropdownModel,
  defaultV2Css,
  SvgRegistry,
} from "survey-core";
import {
  SurveyQuestionMatrixDropdown,
  ReactQuestionFactory,
} from "survey-react-ui";
import {
  localization,
} from "survey-creator-react";

export const CONSTANT_SUM_TYPE = "constant-sum";

defaultV2Css[CONSTANT_SUM_TYPE] = defaultV2Css["matrixdropdown"];

export class QuestionConstantSumModel extends QuestionMatrixDropdownModel {
  constructor(name) {
    super(name);
    this.totalText = "Total";
    this.showHeader = false;
    this.rows = ["Segment 1", "Segment 2", "Segment 3", "Segment 4"];
    const defaultColumn = this.addColumn("defaultColumn");
    const addSymbolColum = this.addColumn("AddSymbolColum");
    this.updateColumnProperties(defaultColumn);
    this.updateSymbolProperties(addSymbolColum);
  }

  getType() {
    return CONSTANT_SUM_TYPE;
  }
  get showHeader() {
    return this.getPropertyValue("showHeader");
  }
  set showHeader(val) {
    this.setPropertyValue("showHeader", val);
  }
  get columnName() {
    return this.getPropertyValue("columnName");
  }

  set columnName(val) {
    this.setPropertyValue("columnName", val);
  }

  get widthCss() {
    return this.getPropertyValue("widthCss");
  }

  set widthCss(val) {
    this.setPropertyValue("widthCss", val);
  }

  get minWidthCss() {
    return this.getPropertyValue("minWidthCss");
  }

  set minWidthCss(val) {
    this.setPropertyValue("minWidthCss", val);
  }

  get minEachRow() {
    return this.getPropertyValue("minEachRow");
  }

  set minEachRow(val) {
    this.setPropertyValue("minEachRow", val);
  }

  get maxEachRow() {
    return this.getPropertyValue("maxEachRow");
  }

  set maxEachRow(val) {
    this.setPropertyValue("maxEachRow", val);
  }

  get answerType() {
    return this.getPropertyValue("answerType");
  }

  set answerType(val) {
    this.setPropertyValue("answerType", val);
  }
  get addTotalBox() {
    return this.getPropertyValue("addTotalBox");
  }

  set addTotalBox(val) {
    this.setPropertyValue("addTotalBox", val);
  }
  get totalFormat() {
    return this.getPropertyValue("totalFormat");
  }
  set totalFormat(val) {
    this.setPropertyValue("totalFormat", val);
  }


  get minTotal() {
    return this.getPropertyValue("minTotal");
  }

  set minTotal(val) {
    this.setPropertyValue("minTotal", val);
  }

  set validationMin(val) {
    val.text =
      "The sum of the values you entered is below the suggested minimum value";
    val.flag = "min";
    this.validators.push(val);
  }

  get MessageMin() {
    return this.getPropertyValue("MessageMin");
  }

  set MessageMin(val) {
    this.setPropertyValue("MessageMin", val);
  }



  get maxTotal() {
    return this.getPropertyValue("maxTotal");
  }
  set maxTotal(val) {
    this.setPropertyValue("maxTotal", val);
  }

  set validationMax(val) {
    val.text =
      "The sum of the values you entered is above the suggested maximum value";
    val.flag = "max";
    this.validators.push(val);
  }

  get MessageMax() {
    return this.getPropertyValue("MessageMax");
  }

  set MessageMax(val) {
    this.setPropertyValue("MessageMax", val);
  }

  get AddSymbol() {
    return this.getPropertyValue("AddSymbol");
  }

  set AddSymbol(val) {
    this.setPropertyValue("AddSymbol", val);
  }
  get Symbol() {
    return this.getPropertyValue("Symbol");
  }

  set Symbol(val) {
    this.setPropertyValue("Symbol", val);
  }
  get Position() {
    return this.getPropertyValue("Position");
  }

  set Position(val) {
    this.setPropertyValue("Position", val);
  }

  updateColumnProperties(column) {
    column.cellType = "text";
    column.totalType = "sum";
    column.totalDisplayStyle = "decimal";
    column.inputType = "number";
  }

  updateSymbolProperties(column) {
    column.cellType = "html";
    column.html = " ";
    column.totalType = "sum";
    column.totalDisplayStyle = "decimal";
    column.width = "1%";
    column.totalFormat = " ";
    column.visible = false;
  }

  addColumn(name) {
    const column = super.addColumn(name);
    this.updateColumnProperties(column);
    return column;
  }
}

export function registerConstantSum() {
  ElementFactory.Instance.registerElement(CONSTANT_SUM_TYPE, (name) => {
    return new QuestionConstantSumModel(name);
  });
}

const locale = localization.getLocale("");
locale.qt[CONSTANT_SUM_TYPE] = "Constant Sum";

Serializer.addClass(
  CONSTANT_SUM_TYPE,
  [
    {
      name: "columnName",
      displayName: "Column Name",
      type: "string",
      category: "Constant Sum",
    },
    {
      name: "widthCss",
      displayName: "Width (CSS)",
      type: "string",
      category: "Constant Sum",
    },
    {
      name: "showHeader",
      displayName: "Show column headers",
      type: "boolean",
      default: false,

    },
    {
      name: "minWidthCss",
      type: "string",
      displayName: "Min width (CSS)",
      category: "Constant Sum",
    },
    {
      name: "minEachRow",
      displayName: "Min for each row",
      type: "number",
      category: "Constant Sum",
    },
    {
      name: "maxEachRow",
      displayName: "Max for each row",
      type: "number",
      category: "Constant Sum",
    },
    {
      name: "answerType",
      category: "Constant Sum",
      choices: [
        { value: "text", text: "Choices" },
        { value: "bars", text: "Bars (Coming Soon)", enableIf: "1=0" },
        { value: "sliders", text: "Sliders (Coming Soon)", enableIf: "1=0" },
        { value: "rating", text: "Rating (Coming Soon)", enableIf: "1=0" },
        { value: "boolean", text: "Yes/No (Coming Soon)", enableIf: "1=0" },
      ],
      type: "dropdown",
      default: "text",
    },
    {
      name: "addTotalBox:boolean",
      category: "Constant Sum",
      categoryIndex: "5",
      default: true,
    },
    {
      name: "totalFormat",
      category: "Constant Sum",
      choices: [
        { value: "none", text: "Whole Number" },
        { value: "percent", text: "Percent" },
        { value: "decimal", text: "Decimal" },
        { value: "currency", text: "Currency" },
      ],
      default: "none",
      type: "dropdown",
      categoryIndex: "5",
      visibleIf(obj) {
        return obj.addTotalBox;
      },
    },

    {
      name: "minTotal",
      displayName: "Min for total",
      type: "number",
      category: "Constant Sum",
      visibleIf(obj) {
        return obj.addTotalBox;
      },
    },

    {
      name: "MessageMin",
      type: "comment",
      category: "Constant Sum",
      visibleIf(obj) {
        return obj.addTotalBox
      },
    },
    {
      name: "maxTotal",
      displayName: "Max for total",
      type: "number",
      category: "Constant Sum",
      visibleIf(obj) {
        return obj.addTotalBox;
      },
    },
    {
      name: "MessageMax",
      type: "comment",
      category: "Constant Sum",
      visibleIf(obj) {
        return obj.addTotalBox 
      },
    },
    {
      name: "AddSymbol:boolean",
      category: "Constant Sum",
      default: false,
    },
    {
      name: "Symbol",
      category: "Constant Sum",
      type: "comment",
      maxLength: 4,
      visibleIf(obj){
        return obj.AddSymbol;
      },
    },
  ],
  function () {
    return new QuestionConstantSumModel("");
  },
  "matrixdropdown"
);

export class SurveyQuestionConstantSum extends SurveyQuestionMatrixDropdown {}

ReactQuestionFactory.Instance.registerQuestion(CONSTANT_SUM_TYPE, (props) => {
  return createElement(SurveyQuestionConstantSum, props);
});

SvgRegistry.registerIconFromSvg(
  CONSTANT_SUM_TYPE,
  `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" fill="none">
  <path d="M4 4L12 4L20 4V7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4 20H12H20V17" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4 20L12 12L4 4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`
);
