import { waitUntilLoaded } from '../../utils/utils';
import { RevIdCallPayload, RevIdCallResponse } from './RevID.types';

export const getRevIdInstance = (): any => window['relevantID' as any];

export const callRevIdService = (payload: RevIdCallPayload): Promise<RevIdCallResponse> => { 
  return new Promise((resolve: any, reject: any) => {
    waitUntilLoaded(getRevIdInstance)
      .then(() => {
        getRevIdInstance().callService(payload, (data: any) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
