import { cloneDeep } from 'lodash';
import { LOGGER_ENABLED } from "../../constants/env";
import { v4 as uuidv4 } from "uuid";

export const isEmptyValue = (value: any): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'string') {
    return value.length === 0;
  }

  return false;
};

export const converterBoolean = (top: boolean, low: boolean) => {
 if (top) {
    return true;
 }
 else if (low) {
    return true;
 }
 else {
    return false;
 }
}

export function formatDate(inputDateStr: string) {
   const inputDate = new Date(inputDateStr);
 
   // Define arrays for month names
   const monthNames = [
     "January", "February", "March", "April", "May", "June",
     "July", "August", "September", "October", "November", "December"
   ];
 
   // Get the month, day, and year from the input date
   const month = monthNames[inputDate.getMonth()];
   const day = inputDate.getDate();
   const year = inputDate.getFullYear();
   const hours = inputDate.getHours();
   const minutes = inputDate.getMinutes();
 
   // Create the formatted date string
   const formattedDate = `${month} ${day}, ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
 
   return formattedDate;
 }

 function fallbackCopyTextToClipboard(text: string): void {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string): Promise<Boolean> {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      resolve(true);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      resolve(true);
    }, function(err) {
      reject();
    });
  });
}

export function removeSpacesFromJSON(jsonString: string): string {
  return jsonString.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, "");
}

export const move = (array: any[], from: number, to: number): void => {
  array.splice(to, 0, array.splice(from, 1)[0]);
};

export function getFirstLetter(str: string): string {
  const match = str.match(/[a-zA-Z]/);

  return match ? match[0].toLocaleUpperCase() : 'H';
}

export const jobLongPooling = (asyncFunction: any, onSuccess: any, onError: any) => {
  const handleData = () => {
    asyncFunction()
      .then((response: any) => {
        const status = response.data.data.job.jobData.state;

        if (status === "completed") {
          onSuccess();
        } else if (status === "error") {
          onError();
        } else {
          setTimeout(handleData, 0);
        }
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });
  };

  handleData();
};

export const addSeparator = (array: any[], suffix: any = ', ', prefix: any = ''): any[] => {
  return array.map((item: any, index: number) => {
    if (index !== array.length - 1) {
      return `${prefix}${item}${suffix}`;
    }

    return `${prefix}${item}`;
  });
};

export function maxItemsWithinLength(strings: string[], maxLength: number): number {
  let totalLength = 0;
  let itemCount = 0;

  for (let str of strings) {
      if (totalLength + str.length > maxLength) {
          break;
      }
      totalLength += str.length;
      itemCount++;
  }

  return itemCount;
}

export function addToList(list: string[], newItem: string): string[] {
  if (!list.includes(newItem)) {
    return [...list, newItem];
  }
  
  return list;
}

export function removeFromList(list: string[], itemToRemove: string): string[] {
  return list.filter(item => item !== itemToRemove);
}

export function addClass(stringToAdd: string, originalString: string): string {
  if (!originalString.includes(` ${stringToAdd} `)) {
      originalString += ` ${stringToAdd} `;
  }
  return originalString;
}

export function removeClass(stringToRemove: string, originalString: string): string {
  return originalString.replace(stringToRemove, "");
}

export const newId = (): string => Math.random().toString(16).slice(2);

export function toggleItemInArray(list: string[], item: string): string[] {
  const items: string[] = cloneDeep(list);
  
  const index = items.indexOf(item);
  if (index === -1) {
      // Item not found, add it
      items.push(item);
  } else {
      // Item found, remove it
      items.splice(index, 1);
  }
  return items;
}

export function updateObjectInArrayById(objects: any[], id: string, newValues: any): any[] {
  return objects.map(obj => {
    if (obj.id === id) {
      // Update the object with new values using object spread syntax
      return { ...obj, ...newValues };
    }
    return obj; // Return the object unchanged if the id doesn't match
  });
}

export function removeObjectFromArrayById(objects: any[] = [], id: string): any[] {
  return objects.filter(obj => obj.id !== id);
}

export function isObjectInArrayById(objects: any[] = [], id: string): boolean {
  return objects.find(obj => obj.id === id);
}

export function findObjectInArrayById(objects: any[] = [], id: string): any {
  return objects.find(obj => obj.id === id);
}

export function setNumberAttributeInInput() {
  setTimeout(() => {
    const inputs = document.querySelectorAll('input[type="number"]');

    inputs.forEach((input) => {
      input.setAttribute("pattern", "\\d*");
      input.setAttribute("inputmode", "numeric");
      input.setAttribute("autocomplete", "cc-number");
      //@ts-ignore
      let newNum;
      input.addEventListener("input", function (event) {
        //@ts-ignore
        if (!!event.target.value) {
          //@ts-ignore
          newNum = event.target.value;
        }
        //@ts-ignore
        if (event.data === "-") {
          //@ts-ignore
          event.target.value = newNum;
        }
      });


      input.addEventListener("keydown", function(event) {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace'];
        //@ts-ignore
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
        //@ts-ignore
        if (event.key === '.' && this.value.includes('.')) {
          event.preventDefault();
          return;
      }
      });
    });
  }, 500);
}

export function print(...args: any[]): void {
  if (LOGGER_ENABLED) {
    console.log(...args);
  }
}

export function id(): string {
  return uuidv4();
}