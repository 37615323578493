import React from "react";

import { Question, Serializer, ElementFactory , SvgRegistry} from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from "survey-react-ui";
import { localization } from "survey-creator-core";

export const COUNTER_TYPE = "counter";

export class CounterModel extends Question {
  getType() {
    return COUNTER_TYPE;
  }
}

class SurveyQuestionCounter extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.question.value = 0;
    this.question.visible = false;
  }
  get question() {
    return this.questionBase;
  }
  handleInputChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ inputValue });
    this.question.value = inputValue;
  };
  renderElement() {
    return (
      <div>
        <input
          maxLength={100}
          type="number"
          className="sd-input sd-text"
          placeholder="Counter value"
          onBlur={this.handleInputChange}
        />
      </div>
    );
  }
}

function registerCounterQuestion() {
  ElementFactory.Instance.registerElement(COUNTER_TYPE, (name) => {
    return new CounterModel(name);
  });
  Serializer.addClass(
    COUNTER_TYPE,
    [],
    function () {
      return new CounterModel("");
    },
    "question"
  );
  const locale = localization.getLocale("");
  locale.qt[COUNTER_TYPE] = "Counter";
  ReactQuestionFactory.Instance.registerQuestion(
    COUNTER_TYPE,
    (props) => {
      return React.createElement(SurveyQuestionCounter, props);
    }
  );
}

SvgRegistry.registerIconFromSvg(
  COUNTER_TYPE,
  `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg>`
);

export { registerCounterQuestion };
