import { SurveyElementBase, ReactElementFactory } from "survey-react-ui";
import React from "react";

export class ItemContentTemplateComponent extends React.Component {
  render() {
    const styles = {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      flexDirection: "column",
      paddingLeft: "10px",
    };

    //@ts-ignore
    const item = this.props.item;
    const locText = item.locText;
    const logoUrl = item.imageUrl;
    const isDesigner = item.isDesignMode;
    const isGhost = item.isGhost;
    const showTitle = item._locOwner.showTitle;

    return (
      <div className="sv-ranking-item__textr" style={styles}>
        {logoUrl && (
          <>
            <img
              className="list-item_image"
              src={logoUrl}
              alt="Logo"
              draggable={false}
            />
          </>
        )}
        {isDesigner && !isGhost && (
          <div
            onClick={() => {}}
            className="svc-context-container svc-image-item-value-controls"
          >
            <span className="svc-context-button" tabIndex={0}>
              <svg
                className="sv-svg-icon"
                role="img"
                style={{ width: "24px", height: "24px" }}
              >
                <use xlinkHref="#icon-file"></use>
                <title>Select a file</title>
              </svg>
            </span>
          </div>
        )}
        {showTitle ? (
          <span className={isGhost ? `disabled-content` : ""}>
            {SurveyElementBase.renderLocString(locText)}
          </span>
        ) : (
          isGhost && (
            <span className="disabled-content">
              {SurveyElementBase.renderLocString(locText)}
            </span>
          )
        )}
      </div>
    );
  }
}

export const initCustomItemRankingImage = (creator) => {
  ReactElementFactory.Instance.registerElement(
    "custom-image-ranking-item",
    (props) => {
      const propsWithCreator = { ...props, creator };
      return React.createElement(
        ItemContentTemplateComponent,
        propsWithCreator
      );
    }
  );
};
