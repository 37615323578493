import { Helpers, Model, PageModel, Question } from "survey-core";
import { convertHtmlToText } from './dynamic-text.utils';
import { OptionItem } from '../types/form.types';
import { COUNTER_TYPE } from '../widget/CounterQuestion.jsx';
import { cloneDeep } from "lodash";
import { IOptionShowOnlyRow } from "../types/survey.types";
import { Variable, VariablesObject } from "../types/variables.types";
import { isCustomVariable } from "./variables.utils";


Helpers.randomizeArray = (array: any): [] => {
  for (let i = array.length - 1; i > 0; i--) {
    const item = array[i];
    if (!item.isFixed) {
      let j;
      do {
        j = Math.floor(Math.random() * (i + 1));
      } while (array[j].isFixed);
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }
  return array;
}

export const getImageIdFromImageLink = (link: string): string =>
  link.split("/").pop()?.split("?")[0] as string;

const sortArray = function (array: any[], mult: number) {
  const fixedEleIndexArray: number[] = [];
  const sortedArray = array.filter((ele, i) => {
      if (ele?.isFixed) fixedEleIndexArray.push(i);
      return !ele.isFixed;
  }).sort(function (a, b) {
      return Helpers.compareStrings(a.calculatedText, b.calculatedText) * mult;
  });
  
  fixedEleIndexArray.forEach((pos, index) => {
      let eleToInsert = array[pos];
      sortedArray.splice(pos, 0, eleToInsert);
  });
  return sortedArray;
};

export const savePositionItem = (survey: any) => {
  let questions = survey.getAllQuestions().filter((q: { getType: () => string }) =>["matrix", "radiogroup", "checkbox", "imagepicker"].includes(q.getType()));
  questions.forEach((question: any) => {
    if (question.getType() === "matrix" && question.customRowsOrder === "random") {
      let originalRows = question.rows;
      let fixedRows = originalRows.filter((row: { isFixed: boolean }) => row.isFixed);
      const fixedRowIndices = fixedRows.map((fixedRow: { rowIndex: number }) => fixedRow.rowIndex);
      let nonFixedRows = originalRows.filter((row: { isFixed: boolean }) => !row.isFixed);
      const randomizedNonFixedRows = Helpers.randomizeArray(nonFixedRows);
      // Combine the fixed and randomized non-fixed rows to get the final array
      const finalRandomizedArray = [];
      for (let i = 0, j = 0, k = 0; i < originalRows.length; i++) {
        if (fixedRowIndices.includes(i)) {
          // Add the fixed row in its original position
          finalRandomizedArray.push(fixedRows[j]);
          j++;
        } else {
          // Add the randomized non-fixed row
          finalRandomizedArray.push(randomizedNonFixedRows[k]);
          k++;
        }
      }
      question.rows = finalRandomizedArray;
    }

    if (["radiogroup", "checkbox", "imagepicker"].includes(question.getType()) && ["asc", "desc"].includes(question.choicesOrder)) {
      let mult = question.choicesOrder === "asc" ? 1 : -1; 
      question.setPropertyValue("visibleChoices", sortArray(question.choices, mult)); 
    }
  });
};

export const showOnlyRow = (options: IOptionShowOnlyRow) => {
  if (
    options.property.name === "isFixed" ||
    options.property.name === "rowIndex"
  ) {
    if (options.parentObj.getType() === "matrix") {
      options.canShow = options.parentProperty.name === "rows";
    }
  }
};

export const getSurveyFromStorage = (surveyId?: string | undefined): Model => {
  throw new Error("Survey not found in the store");
};

export function insertAfter(referenceNode: any, newNode: any) {
  referenceNode.parentNode.parentNode.appendChild(newNode, referenceNode.nextSibling);
}

export const getNumberOfPages = (surveyId: string | undefined): number => {
  const model = getSurveyFromStorage(surveyId);

  return model?.pages?.length || 0;
};

export const getQuestions = (surveyId: string | undefined): Question[] => {
  const model = getSurveyFromStorage(surveyId);

  return model?.getAllQuestions() || [];
};

export const getQuestionsByPage = (surveyId: string | undefined, pageName: string): Question[] => {
  const model = getSurveyFromStorage(surveyId);
  const page = model.getPageByName(pageName)

  return page?.getQuestions(false) || [];
};

export const getCounters = (surveyId: string | undefined): Question[] => {
  const model = getSurveyFromStorage(surveyId);

  return (model?.getAllQuestions() || []).filter((question: Question) => {
    return question['jsonObj']?.type === COUNTER_TYPE;
  });
};

export const mapQuestionToOption = (question: Question | Variable): OptionItem => {
  return {
    label: `${convertHtmlToText(question.title || question.name) || ''} [Id: ${question.name}; Type: ${question?.type || (question as Question)?.getType()}]`,
    value: question.name,
  };
};

export const getPages = (surveyId: string | undefined): PageModel[] => {
  const model = getSurveyFromStorage(surveyId);

  return model.pages;
};

export const isLastPage = (surveyId: string | undefined): boolean => {
  const pages = getPages(surveyId);

  return pages?.length === 1;
};

export const getPageByQuestionName = (surveyId: string | undefined, questionName: string): PageModel | undefined => {
  const pages: PageModel[] = getPages(surveyId);

  return pages.find((page: PageModel) => {
    return page.questions.some((question: Question) => question.name === questionName);
  });
};

export const isLastQuestionOnPage = (surveyId: string | undefined, questionName: string): boolean => {
  const targetPage = getPageByQuestionName(surveyId, questionName);

  if (targetPage) {
    return targetPage.questions.length === 1;
  }

  return false;
};

export const getAllPageNamesFromStorage = (surveyId: string | undefined): string[] => {
  const model = getSurveyFromStorage(surveyId);

  return model.pages?.map((page: PageModel) => page.name);
};

export const removePageByNameUsingStorage = (surveyId: string | undefined, pageName: string): void => {
  const model = getSurveyFromStorage(surveyId);
  const pages = model.pages;

  for (let i = 0; i < pages?.length; i++) {
    if (pages[i].name === pageName) {
      model.removePage(pages[i]);
    }
  }
};

export const pageNumber = (surveyId: string | undefined, page: PageModel | undefined): number => {
  if (!page) {
    return -1;
  }
  
  const model = getSurveyFromStorage(surveyId);

  return model.pages.indexOf(page) + 1;
};

export const getPageByName = (surveyId: string | undefined, pageName: string): PageModel | undefined => {
  if (!pageName) {return}

  const model = getSurveyFromStorage(surveyId);

  return model.pages?.find((page: PageModel) => page.name === pageName);
};

export const findSurveyQuestionById = (id: string, survey: any): Question | undefined => survey.getAllQuestions().find((question: Question) => question.name === id);
export const getAllQuestionNames = (creator: any): string[] => creator.survey.getAllQuestions().map((question: Question) => question.name);
export const getAllPageNames = (creator: any): string[] => creator.survey.pages.map((page: PageModel) => page.name);
export const findCreatorQuestionById = (id: string, creator: any): Question | undefined => creator.survey.getAllQuestions().find((question: Question) => question.name === id);
export const findCreatorPageById = (id: string, creator: any): Question | undefined => creator.survey.pages.find((page: PageModel) => page.name === id);
export const findCreatorParentPageById = (id: string, creator: any): PageModel | undefined => {
  const index = creator.survey.pages.findIndex((page: PageModel) => page.name === id);

  return index > 0 ? creator.survey.pages[index - 1] : undefined;
};

export const findQuestionById = (id: string, surveyId: string | undefined): Question | undefined => getQuestions(surveyId).find((question: Question) => question.name === id);
export const getVariableLabel = (id: string): string => {
  return "";
};

export const toText = (item: any): string => item && item.text;
export const toName = (item: any): string => item && item.name;
export const toValue = (item: any): string => item && item.value;

export function replaceInExpressions(str: string, toReplace: string, replacement: string): string {
  const expressionPattern = /\{[^}]*\}/g; // Regular expression to match expressions like {question1}

  return str.replace(expressionPattern, (match) => {
    // Replace only within the matched expression
    return match.replace(toReplace, replacement);
  });
}

export function isObject(obj: any) {
  return obj !== null && typeof obj === 'object';
}

export function replaceExpressionsInJsonValues(jsonObj: any, toReplace: string, replacement: string) {
  function replaceInObject(obj: any) {
    for (let key in obj) {
      if (isObject(obj[key])) {
        replaceInObject(obj[key]); // Recursive call for nested objects/arrays
      } else if (typeof obj[key] === 'string') {
        obj[key] = replaceInExpressions(obj[key], `{${toReplace}}`, `{${replacement}}`);
        obj[key] = replaceInExpressions(obj[key], `{${toReplace}/`, `{${replacement}/`);
      }
    }
  }

  replaceInObject(jsonObj);
}

export function replaceKeyValueInJson(
  obj: any,
  oldValue: any,
  newValue: any,
  propertyToChange: string
): void {
  if (Array.isArray(obj)) {
    obj.forEach(item => replaceKeyValueInJson(item, oldValue, newValue, propertyToChange));
  } else {
    Object.keys(obj).forEach(key => {
      if (obj[key] === oldValue && key === propertyToChange) {
        obj[key] = newValue;
      } else if (obj[key] && typeof obj[key] === 'object') {
        replaceKeyValueInJson(obj[key], oldValue, newValue, propertyToChange);
      }
    });
  }
}

export const calculateTimeSinceLastUpdate = (update: Date | null  , saved: boolean): string => {
  const MINUTES_IN_HOUR = 60;
  const MINUTES_IN_DAY = 1440;
  const MINUTES_IN_WEEK = 10080;
  const MINUTES_IN_MONTH = 43200;
  
  if( !update ) {
    return "Saved"
  }

  const now: Date = new Date();
  const diff: number = Math.abs(update.getTime() - now.getTime());
  const minutes = Math.floor(diff / 60000);

  if (!saved || !diff) {
    return "Saved";
  }

  switch (true) {
    case (minutes === 0):
      return "Saved";
    case (minutes < MINUTES_IN_HOUR):
      return `Saved ${minutes} minutes ago`;
    case (minutes < MINUTES_IN_DAY):
      const hours = Math.floor(minutes / MINUTES_IN_HOUR);
      return `Saved ${hours} hours ago`;
    case (minutes < MINUTES_IN_WEEK):
      const days = Math.floor(minutes / MINUTES_IN_DAY);
      return `Saved ${days} days ago`;
    case (minutes < MINUTES_IN_MONTH):
      const weeks = Math.floor(minutes / MINUTES_IN_WEEK);
      return `Saved ${weeks} weeks ago`;
    default:
      const months = Math.floor(minutes / MINUTES_IN_MONTH);
      return `Saved ${months} months ago`;
  }
};

export function formatLastResponseTime(timeString: string): string {
  if (!timeString) {
    return 'No response time available';
  } 

  const currentTime = new Date();
  const lastResponseTime = new Date(timeString);
  const timeDifference = (currentTime.getTime() - lastResponseTime.getTime()) / 1000; 

  const minutes = Math.floor(timeDifference / 60);

  if (minutes === 0) {
    return 'Last Response just now';
  } else if (minutes === 1) {
    return 'Last Response 1m ago';
  } else if (minutes < 60) {
    return `Last Response ${minutes}m ago`;
  } else {
    const hours = Math.floor(minutes / 60);
    if (hours === 1) {
      return 'Last Response 1h ago';
    } else if (hours < 24) {
      return `Last Response ${hours}h ago`;
    } else {
      const days = Math.floor(hours / 24);
      if (days === 1) {
        return 'Last Response 1d ago';
      } else {
        return `Last Response ${days}d ago`;
      }
    }
  }
}

export function mergeArraysById(array1: any[], array2: any[]): any[] {
  return array1.map((item1) => {
    const matchingItem = array2.find((item2) => item2.id === item1.id);
    return matchingItem ? { ...item1, ...matchingItem } : item1;
  });
}

export function findParentAttributeValueById(nodeId: string, selector: string) {
  const node = document.getElementById(nodeId);
  let parent = node;
  while (parent && !parent.hasAttribute(selector)) {
      parent = parent.parentElement;
  }
  return parent ? parent.getAttribute(selector) : null;
}
