import { Question } from "survey-core";
import { OptionItem } from "../types/form.types";
import { generateDynamicTextExpression, getChoices, getRateValues, mapChoicesToOptionItem, runDynamicTextExpression } from "./dynamic-text.utils";
import { Choice, DynamicTextAction, DynamicTextChoiceActions, DynamicTextChoiceTextActions, DynamicTextEditorValue, DynamicTextFormValue } from "../types/dynamic-text.types";
import { findQuestionById, findSurveyQuestionById } from "./survey.utils";
import { isEmpty } from "lodash";
import Rule, { RuleGroupType, RuleType } from "react-querybuilder";
import Group from "react-querybuilder"
import { IdentifierTypes } from "../types/variables.types";
import { ExpressionType } from "../types/expression-builder.types";
import { QuestionType } from "../types/question.types";
import { print } from "../utils/utils";


export enum OperatorType {
  Visible = "visible",
  NotVisible = "notvisible",
  Empty = "empty",
  NotEmpty = "notempty",
  Equals = "=",
  NotEqual = "<>",
  Contains = "contains",
  NotContains = "notcontains",
  AnyOfTheFollowing = "anyofthefollowing",
  NotAnyOfTheFollowing = "notanyofthefollowing",
  GreaterThan = ">",
  LessThan = "<",
  GreaterThanOrEqual = ">=",
  LessThanOrEqual = "<=",
};

export const OperatorOptions: OptionItem[] = [
  { value: OperatorType.Visible, label: "Is displayed" },
  { value: OperatorType.NotVisible, label: "Is not displayed" },
  { value: OperatorType.Empty, label: "Is empty" },
  { value: OperatorType.NotEmpty, label: "Is not empty" },
  { value: OperatorType.Equals, label: "Is equal to" },
  { value: OperatorType.NotEqual, label: "Is not equal to" },
  { value: OperatorType.Contains, label: "Contains" },
  { value: OperatorType.NotContains, label: "Does not contain" },
  { value: OperatorType.AnyOfTheFollowing, label: "Any of the following" },
  { value: OperatorType.NotAnyOfTheFollowing, label: "Not any of the following" },
  { value: OperatorType.GreaterThan, label: "Greater than" },
  { value: OperatorType.LessThan, label: "Less than" },
  { value: OperatorType.GreaterThanOrEqual, label: "Greater than or equal to" },
  { value: OperatorType.LessThanOrEqual, label: "Less than or equal to" },
];

export const AllOperators: OperatorType[] = OperatorOptions.map((item: OptionItem) => item.value as OperatorType);

export const PresenceOperators: OperatorType[] = [
  OperatorType.Visible,
  OperatorType.NotVisible,
];

export const EmptinessOperators: OperatorType[] = [
  OperatorType.Empty,
  OperatorType.NotEmpty,
];

export const EqualityOperators: OperatorType[] = [
  OperatorType.Equals,
  OperatorType.NotEqual,
];

export const ContainsLeftOperators: OperatorType[] = [
  OperatorType.Contains,
  OperatorType.NotContains,
];

export const ContainsRightOperators: OperatorType[] = [
  OperatorType.AnyOfTheFollowing,
  OperatorType.NotAnyOfTheFollowing,
];

export const ContainsOperators: OperatorType[] = [
  ...ContainsLeftOperators,
  ...ContainsRightOperators,
];

export const ComparisonOperators: OperatorType[] = [
  OperatorType.GreaterThan,
  OperatorType.LessThan,
  OperatorType.GreaterThanOrEqual,
  OperatorType.LessThanOrEqual,
];

export interface ExpressionParams {
  survey: any;
}

export const evaluateExpressionsTree = (group: RuleGroupType & RuleType, options: ExpressionParams): boolean => {
  if (group.rules) {
    // This is a Group node
    const results = group.rules.map((e: any) => evaluateExpressionsTree(e, options));

    if (group.combinator === 'and') {
      return results.every(result => result);
    } else if (group.combinator === 'or') {
      return results.some(result => result);
    } else {
      throw new Error(`Unknown combinator: ${group.combinator}`);
    }
  } else {
    // This is a Rule node
    return executeRule(group, options);
  }
};

export const executeRule = (rule: RuleType, options: ExpressionParams): boolean => {
  print("+executeRule rule", rule.value);
  print("+executeRule result", evaluateLogicExpressionObj(rule.value, options));

  return evaluateLogicExpressionObj(rule.value, options);
};

export const hsToValue = (hs: DynamicTextFormValue | any, valueType: ExpressionType | undefined, operator: OperatorType, options: ExpressionParams): any => {
  if (valueType === ExpressionType.DynamicText) {
    const {type, identifier, action, row, col, property} = hs;
    
    print("+hsToValue", hs, valueType, operator, PresenceOperators.includes(operator) ? identifier : _deserializeValue(runDynamicTextExpression(generateDynamicTextExpression(hs), options.survey)));
    return PresenceOperators.includes(operator) ? identifier : _deserializeValue(runDynamicTextExpression(generateDynamicTextExpression(hs), options.survey));
  } else {
    print("+hsToValue", hs, valueType, operator, _deserializeValue(hs));
    return _deserializeValue(hs);
  }
};

export const evaluateLogicExpressionObj = (obj: DynamicTextEditorValue, options: ExpressionParams): boolean => {
  try {
    const {lhs, operator, valueType, value} = obj;
    const lhsValue: any = hsToValue(lhs, ExpressionType.DynamicText, operator, options);
    const rhsValue: any = hsToValue(value, valueType, operator, options);
    print("+evaluateLogicExpressionObj lhsValue", lhsValue);
    print("+evaluateLogicExpressionObj rhsValue", rhsValue);

    return _executeOperator(lhsValue, operator, rhsValue, options);
  } catch (error) {
      return false;
  }
};

const _deserializeValue = (value: string): string => {
  let result = value;

  if (value && typeof value === "string") {
    if (value && value[0] === `"` && value[value?.length - 1] === `"`) {
      result = value.slice(1, value?.length - 1);
    }

    if (value && value[0] === "[" && value[value?.length - 1] === "]") {
      result = JSON.parse(value);
    }
  }
  
  return result;
};

export const _executeOperator = (_lhsValue: any, operator: OperatorType, _rhsValue: any, options: ExpressionParams): boolean => {
  let result: boolean = false;
  const values = syncValueTypes(_lhsValue, _rhsValue);
  const lhsValue = values.value1;
  const rhsValue = values.value2;

  print("+1_executeOperator", `(raw type: ${typeof _lhsValue})`, _lhsValue, rhsValue, `(raw type: ${typeof _rhsValue})`);
  
  switch (operator) {
    case OperatorType.Visible:
      result = _isVisible(lhsValue, options);
      break;

    case OperatorType.NotVisible:
      result = !_isVisible(lhsValue, options);
      break;

    case OperatorType.Empty:
      result = isEmptyCustom(lhsValue);
      break;
  
    case OperatorType.NotEmpty:
      result = !isEmptyCustom(lhsValue);
      break;
  
    case OperatorType.Equals:
      result = isEqualCustom(lhsValue, rhsValue); // not strict equation
      break;
  
    case OperatorType.NotEqual:
      result = !isEqualCustom(lhsValue, rhsValue); // not strict equation
      break;
  
    case OperatorType.Contains:
      result = _contains(lhsValue, rhsValue);
      break;
  
    case OperatorType.NotContains:
      result = !_contains(lhsValue, rhsValue);
      break;
  
    case OperatorType.AnyOfTheFollowing:
      result = _anyOfTheFollowing(lhsValue, rhsValue);
      break;
  
    case OperatorType.NotAnyOfTheFollowing:
      result = !_anyOfTheFollowing(lhsValue, rhsValue);
      break;

    case OperatorType.GreaterThan:
      result = +lhsValue > +rhsValue;
      break;

    case OperatorType.LessThan:
      result = +lhsValue < +rhsValue;
      break;
  
    case OperatorType.GreaterThanOrEqual:
      result = +lhsValue >= +rhsValue;
      break;
  
    case OperatorType.LessThanOrEqual:
      result = +lhsValue <= +rhsValue;
      break;
  
    default:
      break;
  }

  print("+2_executeOperator", `(type: ${typeof lhsValue})`, lhsValue, operator, rhsValue, `(type: ${typeof rhsValue})`, "->", result);
  
  return result;
};

const _isVisible = (lhsValue: any, options: ExpressionParams): boolean => {
  const question: Question | undefined = findSurveyQuestionById(lhsValue, options.survey);

  if (question) {
    return question.isVisible;
  }

  return false;
};

const _contains = (lhsValue: any, rhsValue: any): boolean => {
  if (Array.isArray(lhsValue)) {
    if (Array.isArray(rhsValue)) {
      return rhsValue.every((value: any) => lhsValue.some((e: any) => e == value));
    }

    if (!Array.isArray(rhsValue)) {
      return lhsValue.includes(rhsValue);
    }
  }

  if (typeof lhsValue === "string") {
    if (Array.isArray(rhsValue)) {
      return rhsValue.every((value: any) => lhsValue.includes(value));
    }

    if (!Array.isArray(rhsValue)) {
      return lhsValue.includes(rhsValue);
    }
  }

  return false;
};

const _anyOfTheFollowing = (lhsValue: any, rhsValue: any): boolean => {
  if (Array.isArray(rhsValue) && rhsValue?.length > 0) {
    if (Array.isArray(lhsValue)) {
      return lhsValue.every((value: any) => rhsValue.some((e: any) => e == value));
    }

    if (typeof lhsValue === "string") {
      return rhsValue.some((e: any) => e == lhsValue);
    }
  }

  if (!Array.isArray(rhsValue)) {
    if (Array.isArray(lhsValue)) {
      return lhsValue.every((value: any) => rhsValue.includes(value));
    }

    if (typeof lhsValue === "string") {
      return rhsValue.includes(lhsValue);
    }
  }

  return false;
};

export const getPredefinedValues = (
  formValue: DynamicTextEditorValue
): OptionItem[] => {
  if (!formValue?.lhs?.identifier || formValue?.lhs?.type === IdentifierTypes.Variable) {
    return [];
  }

  let predefinedValues: OptionItem[] = [];

  const question: Question | undefined = findQuestionById(formValue.lhs.identifier, "");
  const questionType: QuestionType = question?.getType();
  const action: DynamicTextAction | undefined = formValue.lhs.action;
  
  if (!questionType || ![DynamicTextAction.Answer, ...DynamicTextChoiceActions].includes(action!)) {
    return predefinedValues;
  }

  switch (questionType) {
    case QuestionType.text:
      // month choices - Jan, Feb...
      // 2024-W01
      // 2025-W52
      break;

    case QuestionType.radiogroup:
    case QuestionType.checkbox:
    case QuestionType.dropdown:
    case QuestionType.tagbox:
    case QuestionType.imagepicker:
    case QuestionType.ranking:
    case QuestionType.rankingImage:
    case QuestionType.matrixdropdown:
      // choices
      const useTextAsValue = [...DynamicTextChoiceTextActions].includes(action!);

      predefinedValues = mapChoicesToOptionItem(getChoices(question), useTextAsValue);
      break;

    case QuestionType.boolean:
      // boolean choices - yes/no
      predefinedValues = [{
        label: "Yes",
        value: "true"
      }, {
        label: "No",
        value: "false"
      }];
      break;

    case QuestionType.rating:
      // rating choices - ratingValues
      predefinedValues = mapChoicesToOptionItem(getRateValues(question));
      break;

    case QuestionType.matrix:
      // matrix choices - cols
      predefinedValues = mapChoicesToOptionItem(question.columns as Choice[]);
      break;

      default:
        break;
  }

  return predefinedValues;
};

export function isEqualCustom(a: any, b: any): boolean {
  // Handle case when both are arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    // Compare lengths first
    if (a.length !== b.length) {
      return false;
    }
    // Compare each element
    for (let i = 0; i < a.length; i++) {
      if (!isEqualCustom(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }

  // Handle case when only one is an array
  if (Array.isArray(a) || Array.isArray(b)) {
    return false;
  }

  // Use loose equality for other cases
  return a == b;
}

export function syncValueTypes(value1: any, value2: any): any {
  if (typeof value1 === 'string' && typeof value2 !== 'string') {
    value2 = formatValue(value2);
  } else if (typeof value2 === 'string' && typeof value1 !== 'string') {
    value1 = formatValue(value1);
  } else if (typeof value1 === 'number' && typeof value2 !== 'number') {
    value2 = formatValue(value2);
  } else if (typeof value2 === 'number' && typeof value1 !== 'number') {
    value1 = formatValue(value1);
  } else if (typeof value1 === 'boolean' && typeof value2 !== 'boolean') {
    value2 = formatValue(value2);
  } else if (typeof value2 === 'boolean' && typeof value1 !== 'boolean') {
    value1 = formatValue(value1);
  } else if (Array.isArray(value1) && !Array.isArray(value2)) {
    value2 = [value2];
  } else if (!Array.isArray(value1) && Array.isArray(value2)) {
    value1 = [value1];
  }

  // Sort
  if (Array.isArray(value1)) {
    value1 = value1.sort();
  }
  if (Array.isArray(value2)) {
    value2 = value2.sort();
  }

  return { value1: value1, value2: value2 };
}

export function formatValue(value: any): string {
  if (Array.isArray(value)) {
    return value.join(', ');
  } else {
    return String(value);
  }
}

export function isEmptyCustom(value: any): boolean {
  if (value == null) {
    // Checks for null and undefined
    return true;
  }

  if (typeof value === 'boolean') {
    return false;
  }

  if (typeof value === 'number') {
    return false;
  }

  if (typeof value === 'string') {
    return value.length === 0 || value === "0";
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (value instanceof Set || value instanceof Map) {
    return value.size === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
}