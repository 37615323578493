import { ExecutionOptions, PreviewOptions } from "../types/dynamic-text.types";
import { transformText } from "../utils/dynamic-text.utils";

export const onAfterRenderQuestionHTML = (survey: any, options: any, previewOption: PreviewOptions = PreviewOptions.Taker) => {
  const { question, htmlElement } = options;

  if (question.getType() === "html") {
    const element = htmlElement.querySelector(".sd-html");

    const originalHtml = element.innerHTML;
    const html = transformText(originalHtml, survey, {
      executionOption: ExecutionOptions.Display,
      previewOption
    });

    // Custom Property to store original html with dynamic text
    element.originalhtml = originalHtml;
    element.innerHTML = html;
  }
};

export const onValueChangedHTML = (survey: any, options: any, previewOption: PreviewOptions = PreviewOptions.Taker) => {
  const elements: any[] = Array.from(document.querySelectorAll(`.sd-question--html .sd-html`));

  elements.forEach((element: any) => {
    if (element && element.originalhtml) {

      const html = transformText(element.originalhtml, survey, {
        executionOption: ExecutionOptions.Display,
        previewOption
      });
  
      element.innerHTML = html;
    }
  });
};