export const addScript = (src: string, id: string = String(Date.now())): void => {
  const script = document.createElement('script');
  script.setAttribute('id', id);
  script.setAttribute('src', src);
  document.body.appendChild(script);
}

export const isScriptPresent = (id: string): boolean => !!document?.querySelector(`#${id}`);

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const waitUntilLoaded = (conditionFn: any, intervalMs: number = 100, maxAttempts = 100): Promise<any> => {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    const intervalId = setInterval(() => {
      if (conditionFn()) {
        clearInterval(intervalId);

        resolve(true);
      } else {
        attempts++;

        if (attempts >= maxAttempts) {
          clearInterval(intervalId);

          reject(new Error("Condition not met within the specified time."));
        }
      }
    }, intervalMs);
  });
};