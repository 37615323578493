import * as React from "react";
import {PageModel, PanelModelBase, Question} from "survey-core";
import { SurveyElementBase } from "./reactquestion_element";
import { SurveyPanelBase } from "./panel-base";
import { TitleElement } from "./components/title/title-element";
import { SurveyElementErrors } from "./reactquestion";
import { SvgIcon } from "./components/svg-icon/svg-icon";

export class SurveyPage extends SurveyPanelBase {
  constructor(props: any) {
    super(props);
  }
  protected getPanelBase(): PanelModelBase {
    return this.props.page;
  }
  public get page(): PageModel {
    return this.panelBase as PageModel;
  }

  protected isElementIsQuestion(): boolean {
    const element = this.page;
    const css = (element as PageModel).cssClasses?.page;
    return css?.root?.includes('sd-body__page') && !element.questions.length
    // @ts-ignore
    // return !!css?.mainRoot?.includes('sd-question') && css.flowRoot.includes('sd-question') && this.props.survey.isDesignMode
  }

  protected addQuestion(e: any): void {
    e.stopPropagation();
    //@ts-ignore
    this.creator.currentPage = this.page;
    //@ts-ignore
    this.creator.currentPage.addNewQuestion("text", null, 1)
  }


  protected renderElement(): JSX.Element {
    var title = this.renderTitle();
    var description = this.renderDescription();
    var rows = this.renderRows(this.panelBase.cssClasses);
    const errors = (
      <SurveyElementErrors
        element={this.panelBase}
        cssClasses={this.panelBase.cssClasses}
        creator={this.creator}
      />
    );

    const typoCss = { color: '#6E6E6E', fontFamily: 'Lato', fontSize: 14, fontStyle: 'normal', marginLeft: 5, cursor: 'pointer' }

    return (
      <div ref={this.rootRef} className={this.page.cssRoot}>
        {title}
        {description}
        {errors}
        {
          this.isElementIsQuestion() &&
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, height: 20, alignItems: 'center' }}>
              <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                <SvgIcon
                    size={16}
                    iconName={"icon-plus"}>
                </SvgIcon>
                <span style={typoCss} onClick={(e) => this.addQuestion(e)}> Add Question</span>
              </div>
            </div>
        }
        {rows}
      </div >
    );
  }
  protected renderTitle(): JSX.Element {
    return <TitleElement isPage={true} element={this.page}></TitleElement>;
  }
  protected renderDescription(): JSX.Element | null {
    if (!this.page._showDescription) return null;
    var text = SurveyElementBase.renderLocString(this.page.locDescription);
    return (
      <div className={this.panelBase.cssClasses.page.description}>{text}</div>
    );
  }
}
