import { ExpressionType } from "./expression-builder.types";
import { IdentifierTypes } from "./variables.types";

export enum DynamicTextResponseType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  ListOfStrings = "list-of-strings",
}

export interface DynamicTextFormValue {
  type: IdentifierTypes;
  identifier: any;
  action?: DynamicTextAction;
  row?: string;
  col?: string;
  property?: string;
}

export enum DynamicTextAction {
  Input = "Input",
  QuestionText = "QuestionText",
  Answer = "Answer",

  InitData = "InitData",

  Counter = "Counter",
  AnswerCount = "AnswerCount",

  Choices = "Choices",
  SelectedChoices = "SelectedChoices",
  UnselectedChoices = "UnselectedChoices",
  SelectedOrText = "SelectedOrText",
  FirstAnswer = "FirstAnswer",
  FirstSelectedOrText = "FirstSelectedOrText",
}

export const DynamicTextChoiceTextActions: DynamicTextAction[] = [
  DynamicTextAction.Choices,
  DynamicTextAction.SelectedChoices,
  DynamicTextAction.UnselectedChoices,
];

export const DynamicTextChoiceActions: DynamicTextAction[] = [
  DynamicTextAction.Choices,
  DynamicTextAction.SelectedChoices,
  DynamicTextAction.UnselectedChoices,
  DynamicTextAction.FirstAnswer,
];

export const SelectedOrTextActions: DynamicTextAction[] = [
  DynamicTextAction.SelectedOrText,
  DynamicTextAction.FirstSelectedOrText,
];

export const DynamicTextOption = {
  [DynamicTextAction.Input]: { value: DynamicTextAction.Input, label: "Input Element" },
  [DynamicTextAction.Counter]: { value: DynamicTextAction.Counter, label: "Counter" },
  [DynamicTextAction.InitData]: { value: DynamicTextAction.InitData, label: "Init Data" },
  [DynamicTextAction.AnswerCount]: { value: DynamicTextAction.AnswerCount, label: "Answer Count" },
  [DynamicTextAction.Answer]: { value: DynamicTextAction.Answer, label: "Answer" },
  [DynamicTextAction.QuestionText]: { value: DynamicTextAction.QuestionText, label: "Question Text" },
  [DynamicTextAction.Choices]: { value: DynamicTextAction.Choices, label: "Choices" },
  [DynamicTextAction.SelectedChoices]: { value: DynamicTextAction.SelectedChoices, label: "Selected Choices" },
  [DynamicTextAction.FirstAnswer]: { value: DynamicTextAction.FirstAnswer, label: 'First Answer' },
  [DynamicTextAction.FirstSelectedOrText]: { value: DynamicTextAction.FirstSelectedOrText, label: "First Selected or Text" },
  [DynamicTextAction.UnselectedChoices]: {
    value: DynamicTextAction.UnselectedChoices,
    label: "Unselected Choices",
  },
  [DynamicTextAction.SelectedOrText]: {
    value: DynamicTextAction.SelectedOrText,
    label: "Selected Or Text",
  },
};

export const DynamicTextOptions = [
  DynamicTextOption[DynamicTextAction.Input],
  DynamicTextOption[DynamicTextAction.Counter],
  DynamicTextOption[DynamicTextAction.InitData],
  DynamicTextOption[DynamicTextAction.AnswerCount],
  DynamicTextOption[DynamicTextAction.Answer],
  DynamicTextOption[DynamicTextAction.QuestionText],
  DynamicTextOption[DynamicTextAction.Choices],
  DynamicTextOption[DynamicTextAction.SelectedChoices],
  DynamicTextOption[DynamicTextAction.UnselectedChoices],
  DynamicTextOption[DynamicTextAction.SelectedOrText],
  DynamicTextOption[DynamicTextAction.FirstAnswer],
  DynamicTextOption[DynamicTextAction.FirstSelectedOrText],
];

export interface DynamicTextParams {
  expression: string;
  entityType: string;
  entityID: string;
  entityPath?: string[];
  action: string;
  property: string;
};

export interface jsonObjPartial {
  title: string;
  name: string;
  type: string;
};

export interface Choice {
  value: any;
  text: string;
  imageLink: string;
  customProperty: any;
}

export interface PathParams {
  row?: string;
  col?: string;
  property?: string;
}

export enum ExecutionOptions {
  Raw = "Raw",
  Display = "Display",
}

export enum PreviewOptions {
  Designer = "Designer",
  Preview = "Preview",
  Taker = "Taker",
}

export interface EvaluateExpressionResult {
  valid: boolean;
  result: any;
}

export interface ExecutionParams {
  executionOption: ExecutionOptions;
  previewOption: PreviewOptions;
}

export interface DynamicTextEditorValue {
  lhs: DynamicTextFormValue,
  operator: any,
  valueType?: ExpressionType,
  value?: any,
}

export interface DynamicTextFormValue {
  type: IdentifierTypes;
  identifier: any;
  action?: DynamicTextAction;
  row?: string;
  col?: string;
  property?: string;
}

export interface DynamicTextEditorValue {
  lhs: DynamicTextFormValue,
  operator: any,
  valueType?: ExpressionType,
  value?: any,
}