import { ExpressionGroup, ExpressionBuilderFormValues } from "../../survey-js-extensions/types/expression-builder.types";
import { addBraces, findExpressions } from "../../survey-js-extensions/utils/dynamic-text.utils";
import { OperatorOptions } from "../../survey-js-extensions/utils/expression-builder.utils";
import { findOption } from "../../survey-js-extensions/utils/form.utils";

export const expressionsArrayToExpressionBuilder = (
  array: ExpressionGroup[]
): ExpressionBuilderFormValues => {
  const items = [];
  let index = 0;

  if (array?.length === 0) {return {items: []};}

  while (index < array[0].rules.length) {
    const current = array[0].rules[index];

    if (current) {
      const expression = addBraces(findExpressions(current.value)[0]) as any;
      const operation = current.value.split(`${expression} `)[1].split(" ")[0];
      const value = current.value
        .split(operation)[1]
        ?.trim()
        .replace(/['"]+/g, "");

      items.push({
        expression: {
          expression: expression,
          action:
            findOption(OperatorOptions, operation)?.value || OperatorOptions[0].value,
          value: value,
        },
        logic: array[0].operator || "and",
      });
    }

    index += 1;
  }

  return { items };
};