export enum ExpressionType {
  SurveyJS = 'survey-js',
  DynamicText = 'dynamic-text',
  LogicalOperator = 'logical-operator',
  CustomValue = 'custom-value',
  CustomValueList = 'custom-value-list',
  PredefinedValue = 'predefined-value',
  Variable = 'variable',
}

export enum Operator {
  And = 'and',
  Or = 'or',
}
 
export interface ExpressionObj {
  expressionType: ExpressionType,
  value: any
}
 
export interface ExpressionGroup {
  operator: Operator,
  rules: Array<ExpressionObj | ExpressionObj>
}

export interface ExpressionFormValue {
  expression: string;
  action: string;
  value: string;
}

export interface ExpressionBuilderFormValue {
  expression: ExpressionFormValue;
  logic: string;
}

export interface ExpressionBuilderFormValues {
  items: ExpressionBuilderFormValue[];
}