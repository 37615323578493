import "survey-core/defaultV2.min.css";
import { runDynamicTextExpression, runDynamicTextExpressionURL } from "../../survey-js-extensions/utils/dynamic-text.utils";
import { SurveyTakerService } from "./SurveyTaker.service";
import { incrementCounter } from "./CounterHelper";
import { FlowBlockTypes } from "./FlowEngine.type";
import { surveyWithPageBreaksStore } from "../../store/pageBreak_store";
import { handleNextPage, isSubpage } from "./utils/flow-engine.utils";
import { getLocalStorageItem, localStorageKeys, setLocalStorageItem } from "./types/constant";
import { Helpers, Model } from "survey-core";
import { isCustomVariable, isFlowEngineHasVariables } from "../../survey-js-extensions/utils/variables.utils";
import { IdentifierTypes, IdentifierFormItem } from "../../survey-js-extensions/types/variables.types";
import { variablesStore } from "../../store/variables_store";
import { isEmpty } from "lodash";
import { evaluateExpressionsTree, hsToValue } from "../../survey-js-extensions/utils/expression-builder.utils";
import { ExpressionObj, ExpressionType } from "../../survey-js-extensions/types/expression-builder.types";
import { DynamicTextEditorValue } from "../../survey-js-extensions/types/dynamic-text.types";

class SurveyFlowCtrl {
  survey: any;
  flow: any;
  currentPosition: number;
  flowPath: any[];
  currentAction: any;
  service = new SurveyTakerService();
  currentPageIndex: number;
  originalToNewPagesMap: any;
  pageIndex: number;
  surveyPrevPageIndex: any[] = [];
  surveyPrevPageIndexes: any[] = [];
  isFirstSubpage: boolean = true;
  currentMainPage: string = "";
  surveyProps: any;
  isHidePreviousButtonFromMainSurvey = false;
  allowDeleteRespondentData: boolean = false;
  noOfPagesRender:number = 0;
  saveResults: Function;
  isPartialRecorded: boolean = false;

  constructor(survey: any, flow: any, surveyProps: any, saveResults: Function) {
    this.isHidePreviousButtonFromMainSurvey = survey.showPrevButton;
    this.survey = survey;
    this.flow = flow;
    this.currentPosition = 0;
    this.flowPath = [];
    this.currentPageIndex = 0;
    this.survey.showPrevButton = false;
    this.surveyProps = surveyProps;
    // updated flow engine map
    let updateFlowDataFromStore = surveyWithPageBreaksStore.getSurveyMap;
    this.originalToNewPagesMap = updateFlowDataFromStore || [];

    this.saveResults = saveResults;
    // page index
    const pageIndexStorage = surveyWithPageBreaksStore.getSurveyPageIndex;
    this.pageIndex = pageIndexStorage || 0;

    this.survey.onCurrentPageChanged.add(
      (sender: { currentPage: { name: any } }, options: any) => {
        let currentPageName = sender.currentPage.name;
        if (isSubpage(currentPageName)) {
          // Show back button if we're on a broken/subpage
          this.survey.showPrevButton = true;
        } else {
          // Hide back button if we're on a main page
          this.survey.showPrevButton = false;
        }
      }
    );

    this.survey.nextPage = () => this.nextPage();
    this.survey.prevPage = () => this.prevPage();

    Object.defineProperty(this.survey, "isLastPage", {
      get: () => this.isLastPage(),
    });
  }

  runCondition(condition: any) {
    if (!condition) return true;
    if (this.survey.runCondition(condition)) {
      return true;
    }
    return false;
  }

  hasVisibleElements(page: any): boolean {
    return page.elements.some((element: any) => 
     {
       
       
       if (element.isVisible){
         return true;
       }

       console.log(element,element.isVisible, element.isVisibleInSurvey)

       return false;

       /*if (element.expressionInfo?.visibleIf?.canRun){
         
         return this.survey.runExpression(element.expressionInfo?.visibleIf?.runner?.expression);
       } 

       return element.visible
       */
     });
  }

  findNextSubPage(originalPage: any, currentIndex: number): any {
    if(this.originalToNewPagesMap[originalPage] && currentIndex < this.originalToNewPagesMap[originalPage].length) {
      let nextSubPage = this.survey.getPageByName(
        this.originalToNewPagesMap[originalPage][currentIndex]
      );

      if (!nextSubPage.visible || !this.hasVisibleElements(nextSubPage)) { 
        handleNextPage(nextSubPage, this.survey);
        return this.findNextSubPage(originalPage, currentIndex + 1);
      } else {
        this.currentPageIndex = currentIndex;
        return nextSubPage;
      }
    }
    return false;
  }

  traverse(flowStep: any, parent?: any, actionIndex = 0) {
    let actions = flowStep?.actions;
    for (let i = actionIndex; i < actions?.length; i++) {
      let action = actions[i];
      if (action.type === "showPage") {
        let showedPage = true;
        this.currentPageIndex = 0;
        if (
          this.originalToNewPagesMap[action.page] &&
          this.originalToNewPagesMap[action.page].length > 0
        ) {
          let nextSubPage = this.findNextSubPage(action.page, 0);

          if (nextSubPage) { 
            this.survey.currentPage = nextSubPage;
          } else {
            showedPage = false;
          }
        } else {
          let nextPage = this.survey.getPageByName(action.page);
          if (!nextPage.visible || !this.hasVisibleElements(nextPage)) {
            showedPage = handleNextPage(nextPage, this.survey);
          } else {
            this.survey.currentPage = nextPage;
          }
        }

        if (showedPage) {
          this.flowPath.push({
            flowStep: flowStep,
            parent: parent,
            actionIndex: i,
          });
          this.currentMainPage = action.page;
          this.isFirstSubpage = true;    
          this.saveUserFlowPosition(flowStep, parent, i); 
          
          if (typeof this.saveResults === 'function') {
            if (this.noOfPagesRender === 1 && this.isPartialRecorded) return;
            if (this.noOfPagesRender === 1 && !this.isPartialRecorded) this.isPartialRecorded = true;
            this.saveResults(this.survey.data);
          }
          return false;
        }
      }

      if (action.type === "endSurvey") {
        this.currentAction = action;

        if (!action.isDefault) {
          if (action.redirectUrl) {
            this.survey.showCompletedPage = false;
            let redirectUrl = runDynamicTextExpressionURL(action.redirectUrl, this.survey)
            setLocalStorageItem(localStorageKeys.FLOW_REDIRECT, redirectUrl, {takerId: this.surveyProps.takerId});

          } else
          if (action.completedHTML) {
            this.survey.completedHtml = `<h3>${action.completedHTML}<h3>`;
          }

          if (action.discardResponse) {
            alert("Your Response will be discarded (Not implemented yet)");
          }

          if (action.deleteRespondentData) {
            this.allowDeleteRespondentData = true;
            this.deleteSurveyResponse();
          }
        }

        let nextPage = this.survey.getPageByName("aethlkejrlkha");
        this.survey.currentPage = nextPage;

        this.survey.completeLastPage();
        return false; //exit loop and stop
      }

      if (action.type === FlowBlockTypes.SetIdentifier) {
        action.items.forEach((item: DynamicTextEditorValue) => {
          const {lhs, operator, valueType, value} = item;
          const valueToSet: any = hsToValue(value, valueType, operator, {survey: this.survey});

          // Custom Variables and Questions are stored in the Survey Model
          // Other Variables are stored in the variablesStore
          if (lhs.type === IdentifierTypes.Variable && !isCustomVariable(lhs.identifier)) {
            variablesStore.setValue(lhs.identifier as any, valueToSet);
          } else {
            this.survey.setValue(lhs.identifier, valueToSet);
          }
        });
      }

      if (action.type === "incrementCounter") {
        incrementCounter(this.survey, action.counter, 1);
      }

      if (action.type === "step") {
        const result = this.processBranchExpressions(action);

        if (result) {
          if (!this.traverse(action, { flowStep, parent, actionIndex: i }, 0)) {
            return false; //exit loop since the below exited
          }
        }
      }

      if (action.type === "randomize") {
        action.actions = Helpers.randomizeArray(action.actions);
        //exit loop since the below exited
        if (!this.traverse(action, { flowStep, parent, actionIndex: i }, 0)) return false;
      }
    }
    if (parent) {
      //exit loop since the below exited
      if (!this.traverse(parent.flowStep, parent.parent, parent.actionIndex + 1)) return false;
    }
    return true; //let caller continue // After finishing all actions at current level // go one level up if parent step exists //if (parent) { //  this.traverse(parent, parent.parent, actionIndex); //}
  }

  processBranchExpressions(substep: any): boolean {
    if (Array.isArray(substep.expression)) {
      for (let i = 0; i < substep.expression.length; i++) {
        let result;

        if (substep.expression[i].operator === "or") {
          result = substep.expression[i].rules.some((rule: any) => {
            return this.evaluateExpression(rule);
          });
        } else if (substep.expression[i].operator === "and") {
          result = substep.expression[i].rules.every((rule: any) => {
            return this.evaluateExpression(rule);
          });
        }

        // if the current expression resulted in false, stop execution and return false
        if (!result) return false;
      }
    } else {
      if (!isEmpty(substep.expression)) {
        return evaluateExpressionsTree(substep.expression, {survey: this.survey});
      }
    }
    // if all expressions resulted in true, return true
    return true;
  }

  evaluateExpression(action: ExpressionObj): any {
    if (action.expressionType === ExpressionType.SurveyJS) {
      return this.survey.runExpression(action.value);
    }

    if (action.expressionType === ExpressionType.DynamicText) {
      return this.survey.runExpression(
        runDynamicTextExpression(action.value, this.survey)
      );
    }

    if (action.expressionType === ExpressionType.Variable) {
      return variablesStore.getValue(action.value as any);
    }

    return;
  }

  nextStep() {
    this.traverse(this.flow[this.currentPosition++]);    
  }

  start() {
    this.resetPageIndex();
    this.currentPosition = 0;
    const userFlowPosition = getLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, {takerId: this.surveyProps.takerId}) || "";
    if (userFlowPosition) {
      const { flowStep, parent, actionIndex, noOfPagesRender} = JSON.parse(userFlowPosition);
      if (noOfPagesRender) {
        this.noOfPagesRender = noOfPagesRender;
        this.isPartialRecorded = true;
      };
      this.traverse(flowStep, parent, actionIndex);
    }
    else {
      this.nextStep();
    }

    if (isFlowEngineHasVariables(this.flow)) {
      this.nextPage();
    }
  }

  resetPageIndex() {
    this.pageIndex = 0;
    surveyWithPageBreaksStore.handleSurveyPageIndex(this.pageIndex);
  }

  nextPage() {
    if (!this.survey.isCurrentPageValid) {
      return;
    }
    
    if (this.flowPath.length > 0) {
      const lastPosition = this.flowPath[this.flowPath.length - 1];
      const originalPageName = this.survey.currentPage.name.split("_")[0];

      if (originalPageName === this.currentMainPage) {
        this.isFirstSubpage = false;
      }

      this.currentPageIndex++;
      this.noOfPagesRender++;

      if (
        this.currentPageIndex <
        this.originalToNewPagesMap[originalPageName]?.length
      ) {
        let nextSubPage = this.survey.getPageByName(
          this.originalToNewPagesMap[originalPageName][this.currentPageIndex]
        );
        if (!nextSubPage.visible || !this.hasVisibleElements(nextSubPage)) {
          handleNextPage(nextSubPage, this.survey);
          this.nextPage();
          return;
        } else {
          this.survey.currentPage = this.survey.getPageByName(
            this.originalToNewPagesMap[originalPageName][this.currentPageIndex]
          );
        }
      } else {
        this.currentPageIndex = 0;
        this.flowPath.pop();
        this.traverse(
          lastPosition.flowStep,
          lastPosition.parent,
          lastPosition.actionIndex + 1
        );
      }
    } else {
      this.nextStep();
    }

    let currentPageName = this.survey.currentPage.name;
    if (this.isHidePreviousButtonFromMainSurvey === false) {
      this.survey.showPrevButton = false;
    } else {
      this.survey.showPrevButton = isSubpage(currentPageName) && !this.isFirstSubpage;
    }
    this.pageIndex++;
    surveyWithPageBreaksStore.handleSurveyPageIndex(this.pageIndex);
  }

  prevPage() {
    this.pageIndex--;
    if (this.flowPath.length > 0) {
      const lastPosition = this.flowPath[this.flowPath.length - 1];
      const originalPageName = this.survey.currentPage.name.split("_")[0];
      this.surveyPrevPageIndexes.pop();
      this.currentPageIndex--;
      if (
        this.currentPageIndex >= 0 &&
        this.originalToNewPagesMap[originalPageName]?.length > 0
      ) {
        this.survey.currentPage = this.survey.getPageByName(
          this.originalToNewPagesMap[originalPageName][this.currentPageIndex]
        );
        this.isFirstSubpage = this.currentPageIndex === 0;
      } else if (this.pageIndex > 0) {
        this.currentPageIndex = 0;
        this.flowPath.pop();
        const prevActionIndex = this.surveyPrevPageIndexes.pop();
        this.traverse(
          lastPosition.flowStep,
          lastPosition.parent,
          prevActionIndex
        );
        this.isFirstSubpage = false;
      }
    }
    let currentPageName = this.survey.currentPage.name;
        // Make sure the prev button is set to false if survey.showPreviousButton is false
  if (this.isHidePreviousButtonFromMainSurvey === false) {
    this.survey.showPrevButton = false;
  } else {
    this.survey.showPrevButton = isSubpage(currentPageName) && !this.isFirstSubpage;
  }
    surveyWithPageBreaksStore.handleSurveyPageIndex(this.pageIndex);
  }

  isLastPage() {
    const originalPageName = this.survey.currentPage.name.split("_")[0];
    return (
      this.currentAction &&
      this.currentAction.type === "endSurvey"
    );
    // Ensure that we are on the last new page, created due to page-break, before marking it as last page
  }

  async deleteSurveyResponse() {
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: this.surveyProps.takerId}) || "";
    if (token) {
      const response = await this.service.deleteSurveyResponse(token);
      if (!response.data) {
        return;
      }
    }
  }

  saveUserFlowPosition(flowStep: any, parent: any, actionIndex: number) {
    setLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, JSON.stringify({ flowStep, parent, actionIndex, noOfPagesRender: this.noOfPagesRender }), { takerId: this.surveyProps.takerId });
  }
}

export default SurveyFlowCtrl;
