import { action, computed, makeObservable, observable } from "mobx";
import { CVariable, HVariable, Variables, VariablesDefaults } from "../survey-js-extensions/types/variables.types";
import { cloneDeep } from "lodash";

export class VariablesStore {
  _values: Variables = VariablesDefaults;

  constructor() {
    makeObservable(this, {
      _values: observable,

      setValues: action,
      patchValues: action,
      clearStore: action,
    });
  }

  setValues = (values: Partial<Variables>): void => {
    this._values = values as Variables;
  };

  getValues = (): Variables => {
    return this._values;
  };

  getValue = (name: HVariable | CVariable): any => {
    return this._values[name];
  };

  setValue = (name: HVariable | CVariable, value: any): void => {
    this._values[name] = value;
  };

  getValuesForPayload = (): Partial<Variables> => {
    const payload = {
      [HVariable.DQReason]: this._values[HVariable.DQReason],
      [HVariable.DQStatus]: this._values[HVariable.DQStatus],
      [HVariable.PanelId]: this._values[HVariable.PanelId],
      [HVariable.FraudRisk]: this._values[HVariable.FraudRisk],
      [HVariable.IdDuplicate]: this._values[HVariable.IdDuplicate],
      [HVariable.Flags]: this._values[HVariable.Flags],
      [HVariable.FinalStatus]: this._values[HVariable.FinalStatus],
      [HVariable.ReCaptchaV3]: this._values[HVariable.ReCaptchaV3],
    };

    return payload;
  };

  patchValues = (values: Partial<Variables>): void => {
    this._values = { ...this._values, ...values };
  };

  clearStore = (): void => {
    this._values = cloneDeep(VariablesDefaults);
  };
}

export const variablesStore = new VariablesStore();
