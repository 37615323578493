import { cloneDeep } from "lodash";
import { Block, FlowBlockTypes } from "../SurveyTaker/types/FlowBlockTypes";
import { FLOW_ENGINE_VERSION } from "../../constants/env";
import { ExpressionBuilderFormValue, ExpressionType } from "../../survey-js-extensions/types/expression-builder.types";
import { DynamicTextAction, DynamicTextEditorValue, DynamicTextParams } from "../../survey-js-extensions/types/dynamic-text.types";
import { IdentifierFormItem, IdentifierTypes, QUESTION } from "../../survey-js-extensions/types/variables.types";
import { OperatorType } from "../../survey-js-extensions/utils/expression-builder.utils";
import { entityPathToParams, extractDynamicTextParams, findExpressions } from "../../survey-js-extensions/utils/dynamic-text.utils";
import { findQuestionById } from "../SurveyTaker/survey.utils";
import { expressionsArrayToExpressionBuilder } from "../SurveyTaker/survey-designer.utils";
import { v4 as uuidv4 } from "uuid";

export function compareVersions(version1: string, version2: string): string {
  // Split the versions into their individual parts
  const v1Parts = version1.split('.').map(Number);
  const v2Parts = version2.split('.').map(Number);

  // Compare each part of the versions
  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const v1Part = v1Parts[i] || 0; // Default to 0 if part is missing
      const v2Part = v2Parts[i] || 0; // Default to 0 if part is missing

      if (v1Part < v2Part) {
          return "lower";
      } else if (v1Part > v2Part) {
          return "higher";
      }
  }

  // If all parts are equal, the versions are the same
  return "equal";
}

export function incrementVersion(version: string, type: "major" | "minor" | "patch"): string {
  // Split the version into its parts
  const parts = version.split('.').map(Number);

  // Ensure the version has at least three parts (major, minor, patch)
  while (parts.length < 3) {
      parts.push(0);
  }

  // Increment the specified part of the version
  switch (type) {
      case "major":
          parts[0] += 1;
          parts[1] = 0;
          parts[2] = 0;
          break;
      case "minor":
          parts[1] += 1;
          parts[2] = 0;
          break;
      case "patch":
          parts[2] += 1;
          break;
  }

  // Join the parts back into a version string
  return parts.join('.');
}

export function isLowerVersion(version1: string, version2: string): boolean {
  return compareVersions(version1, version2) === "lower";
}

export function isHigherVersion(version1: string, version2: string): boolean {
  return compareVersions(version1, version2) === "higher";
}

export function isSameVersion(version1: string, version2: string): boolean {
  return compareVersions(version1, version2) === "equal";
}

export function getFlowEngineVersion(surveyFlow: Block[]): string {
  return surveyFlow[0]?.version || "0.0.1";
};

export function canUpgradeFlowEngineVersion(surveyFlow: Block[]): boolean {
  const version = getFlowEngineVersion(surveyFlow);

  return version ? isLowerVersion(version, FLOW_ENGINE_VERSION) : false;
};

export function upgradeFlowEngineVersion(originalBlocks: Block[]): Block[] {
  const blocks = cloneDeep(originalBlocks);
  const version = getFlowEngineVersion(blocks);
  const toVersion = incrementVersion(version, "patch");

  try {
    if (version) {
      _upgradeFlowEngine(blocks, version, toVersion);
    }
  
    blocks[0].version = toVersion;

    return blocks;
  } catch (error) {
    console.log('Error while versioning', error, originalBlocks);
    
    return originalBlocks;
  }
}

export function upgradeToLatestVersion(surveyFlow: Block[]): Block[] {
  if (canUpgradeFlowEngineVersion(surveyFlow)) {
    return upgradeFlowEngineVersion(surveyFlow);
  }
  return surveyFlow;
}

export function _upgradeFlowEngine(blocks: Block[], fromVersion: string, toVersion: string): void {
  for (let i = 0; i < blocks.length; i++) {
    if (blocks[i].id) {
      if (fromVersion === "0.0.1") {
        blocks[i] = upgradeFrom_0_0_1_To_0_0_2(blocks[i]);
      }

      if (fromVersion === "0.0.2") {
        blocks[i] = upgradeFrom_0_0_2_To_0_0_3(blocks[i]);
      }

      if (fromVersion === "0.0.3") {
        blocks[i] = upgradeFrom_0_0_3_To_0_0_4(blocks[i]);
      }

      if (fromVersion === "0.0.4") {
        blocks[i] = upgradeFrom_0_0_4_To_0_0_5(blocks[i]);
      }
    }

    // Recursively search in children
    if (blocks[i].actions?.length > 0) {
      _upgradeFlowEngine(blocks[i].actions, fromVersion, toVersion);
    }

    if (blocks[i].type === FlowBlockTypes.EndSurvey && blocks[i].identifiers?.length > 0) {
      _upgradeFlowEngine(blocks[i].identifiers, fromVersion, toVersion);
    }
  }
}

export function upgradeFrom_0_0_1_To_0_0_2(block: Block): Block {
  if (block.type === FlowBlockTypes.SetIdentifier) {
    const rootContent = {
      id: block.id,
      type: block.type,
    };

    const content = cloneDeep(block);
    delete content.id;
    delete content.type;

    const newBlock = {
      ...rootContent,
      items: [content]
    };

    return newBlock;
  }

  return block;
}

export function upgradeFrom_0_0_2_To_0_0_3(block: Block): Block {
  if (block.type === FlowBlockTypes.SetIdentifier) {
    const newItems = block.items.map((item: any) => {
      const newItem: any = {};

      // identifierType
      newItem.identifierType = item.identifierType === 1 ? "question" : "variable";

      // expressionType
      newItem.expressionType = item.expressionType === "hvar" ? "variable" : item.expressionType;

      // value
      newItem.value = item.value;

      // identifier
      newItem.identifier = item.objectType === "question" ? item.question : item.hvarType?.value;

      return newItem;
    });

    block.items = newItems;
  }

  return block;
}

export function upgradeFrom_0_0_3_To_0_0_4(block: Block): Block {
  if (block.type === FlowBlockTypes.Step) {
    const items: ExpressionBuilderFormValue[] = expressionsArrayToExpressionBuilder(block.expression)?.items;

    block.expression = {
      combinator: items[0].logic,
      rules: items.map((item: ExpressionBuilderFormValue) => {
        const {action, expression, value} = item.expression;
        const params: DynamicTextParams | null = extractDynamicTextParams(findExpressions(expression)[0]);
        
        return {
          id: uuidv4(),
          field: "item",
          operator: "=",
          valueSource: "value",
          value: {
            lhs: {
              type: params?.entityType === QUESTION ? QUESTION : ExpressionType.Variable,
              identifier: params?.entityID,
              action: params?.action || undefined,
            },
            operator: action,
            valueType: ExpressionType.CustomValue,
            value: value
          }
        };
      })
    };
  }

  return block;
}

export function upgradeFrom_0_0_4_To_0_0_5(block: Block): Block {
  if (block.type === FlowBlockTypes.SetIdentifier) {
    const newItems = block.items.map((item: IdentifierFormItem) => {
      const newItem: DynamicTextEditorValue = {} as any;

      newItem.lhs = {
        type: item.identifierType,
        identifier: item.identifier,
      };

      if (item.identifierType === IdentifierTypes.Question) {
        newItem.lhs.action = DynamicTextAction.Answer;
      }

      newItem.operator = OperatorType.Equals;

      newItem.valueType = item.expressionType as any;

      if (newItem.valueType === ExpressionType.Variable) {
        newItem.valueType = ExpressionType.DynamicText;

        newItem.value = {
          type: IdentifierTypes.Variable,
          identifier: item.value
        };
      } else if (newItem.valueType === ExpressionType.DynamicText) {
        const expressionParams = extractDynamicTextParams(findExpressions(item.value)[0]);

        newItem.value = {
          type: expressionParams?.entityType === QUESTION ? IdentifierTypes.Question : IdentifierTypes.Variable,
          identifier: expressionParams?.entityID,
        };

        if (expressionParams?.action) {
          newItem.value.action = expressionParams?.action;
        }

        if (expressionParams?.entityPath) {
          const question = findQuestionById(expressionParams.entityID);

          if (question) {
            const pathParams = entityPathToParams(expressionParams.expression, question);
            const { row, col, property } = pathParams || {};
  
            if (row) {
              newItem.value.row = row;
            }

            if (col) {
              newItem.value.col = col;
            }

            if (property) {
              newItem.value.property = property;
            }
          }
        }
      } else {
        newItem.value = item.value;
      }
      
      return newItem;
    });

    block.items = newItems;
  }

  return block;
}

