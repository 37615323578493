import { SurveyTrigger, HashTable } from "survey-core";

class IncrementQuotaTrigger extends SurveyTrigger {
  static triggerName = "incrementquotatrigger";

  getType(): string {
    return IncrementQuotaTrigger.triggerName;
  }
  public get targetQuota(): string {
    return this.getPropertyValue("targetQuota", "");
  }
  public set targetQuota(val: string) {
    this.setPropertyValue("targetQuota", val);
  }
  public get initialNumber(): string {
    return this.getPropertyValue("initialNumber", "");
  }
  public set initialNumber(val: string) {
    this.setPropertyValue("initialNumber", val);
  }
  protected onSuccess(
    values: HashTable<any>,
    properties: HashTable<any>
  ): void {
    if (
      !this.owner ||
      !this.targetQuota ||
      //@ts-ignore
      this.owner.getQuestionByName(this.targetQuota).getType() != QUOTA_TYPE
    )
      return;
    if (this.isRealExecution()) {
      //@ts-ignore
      let resValue: number = this.initialNumber;
      //@ts-ignore
      let currentValue = this.owner.getValue(this.targetQuota);
      if (currentValue !== undefined) {
        currentValue = Number(currentValue);
        if (currentValue < resValue) {
          resValue++;
        } else {
          resValue = currentValue + 1;
        }
      } else {
        resValue++;
      }
      //@ts-ignore
      this.owner.setValue(this.targetQuota, resValue);
    }
  }
}


export default IncrementQuotaTrigger;
