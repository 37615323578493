import { Question } from "./question";
import { Serializer} from "./jsonobject";
import { QuestionFactory } from "./questionfactory";

export class QuestionPageBreakModel extends Question {
  constructor(name: string) {
    super(name);
    this.titleLocation = "hidden";
  }

  getType(): string  {
    return 'pagebreak';
  }
}

Serializer.addClass(
    "pagebreak",
    [
      { name: "showCommentArea:switch", layout: "row", visible: true, category: "general" },

    ],
    function () {
      return new QuestionPageBreakModel('');
    },
    "question"
);

QuestionFactory.Instance.registerQuestion("pagebreak", (name) => {
  var q = new QuestionPageBreakModel(name);
  return q;
});
