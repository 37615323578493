import React from "react";
import { Question, ElementFactory, Serializer, SvgRegistry } from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from "survey-react-ui";
import { localization } from "survey-creator-core";

export const INIT_DATA_TYPE = "initdata";

const locale = localization.getLocale("");
locale.qt[INIT_DATA_TYPE] = "Init Data";

export class InitDataModel extends Question {
  constructor(name) {
    super(name);
    this.visible = false;
  }

  getType() {
    return INIT_DATA_TYPE;
  }

  get dataType() {
    return this.getPropertyValue("dataType");
  }

  set dataType(val) {
    this.setPropertyValue("dataType", val);
  }

  get queryString() {
    return this.getPropertyValue("queryString");
  }

  set queryString(val) {
    this.setPropertyValue("queryString", val);
  }
}

class SurveyQuestionInitData extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.questionBase.getPropertyValue("dataType") || "",
      inputValue: this.questionBase.getPropertyValue("queryString") || "",
    };
  }

  get question() {
    return this.questionBase;
  }

  handleOptionChange = (e) => {
    const selectedOption = e.target.value;
    this.setState({ selectedOption });
    this.questionBase.setPropertyValue("dataType", selectedOption);
  };

  handleInputChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ inputValue });
    this.questionBase.setPropertyValue("queryString", inputValue);
  };

  renderElement() {
    return (
      <div className="sd-question__content">
        <div className="sd-selectbase">
          <div className="sv-dropdown_select-wrapper">
            <select
              className="sd-input sd-dropdown sd-dropdown--empty"
              value={this.state.selectedOption}
              onChange={this.handleOptionChange}
            >
              <option value="">Select</option>
              <option value="QueryString">QueryString</option>
            </select>
            <br />
            {this.state.selectedOption === "QueryString" && (
              <input
                maxLength={100}
                type="text"
                className="sd-input sd-text"
                placeholder="Enter Query String"
                onChange={this.handleInputChange}
                defaultValue={this.state.inputValue}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function init() {
  ElementFactory.Instance.registerElement(INIT_DATA_TYPE, (name) => {
    return new InitDataModel(name);
  });

  Serializer.addClass(
    INIT_DATA_TYPE,
    [
      {
        name: "dataType",
        displayName: "Question Type",
        category: "general",
        visible: true,
      },
      {
        displayName: "Enter {dataType} value",
        name: "queryString",
        category: "general",
        visible: true,
      },
    ],
    () => new InitDataModel(""),
    "question"
  );

  ReactQuestionFactory.Instance.registerQuestion(
    INIT_DATA_TYPE,
    (props) => {
      return React.createElement(SurveyQuestionInitData, props);
    }
  );
}

SvgRegistry.registerIconFromSvg(
  INIT_DATA_TYPE,
  `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-database-fill" viewBox="0 0 16 16">
  <path d="M3.904 1.777C4.978 1.289 6.427 1 8 1s3.022.289 4.096.777C13.125 2.245 14 2.993 14 4s-.875 1.755-1.904 2.223C11.022 6.711 9.573 7 8 7s-3.022-.289-4.096-.777C2.875 5.755 2 5.007 2 4s.875-1.755 1.904-2.223Z"/>
  <path d="M2 6.161V7c0 1.007.875 1.755 1.904 2.223C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777C13.125 8.755 14 8.007 14 7v-.839c-.457.432-1.004.751-1.49.972C11.278 7.693 9.682 8 8 8s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972Z"/>
  <path d="M2 9.161V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13s3.022-.289 4.096-.777C13.125 11.755 14 11.007 14 10v-.839c-.457.432-1.004.751-1.49.972-1.232.56-2.828.867-4.51.867s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972Z"/>
  <path d="M2 12.161V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13v-.839c-.457.432-1.004.751-1.49.972-1.232.56-2.828.867-4.51.867s-3.278-.307-4.51-.867c-.486-.22-1.033-.54-1.49-.972Z"/>
</svg>`
);
export default init;
