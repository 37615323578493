import { DynamicTextEditorValue } from "./dynamic-text.types";
import { ExpressionType } from "./expression-builder.types";
import { OptionItem } from "./form.types";

export interface VariablesObject {
  variables: Variable[];
  quotas: Quota[];
}

export interface Quota {
  id?: string;
  title?: string;
  type?: QuotaType;
  name: string;
  count?: string;
  quota?: string;
  expression?: DynamicTextEditorValue;
}

export interface Variable {
  id?: string;
  type: VariableType;
  title?: string;
  name: string;
  defaultValue?: string;
  queryParam?: string;
}

export enum QuotaType {
  Quota = "quota",
};

export enum VariableType {
  UrlParam = "url-param",
  Counter = "counter",
  Value = "value",

  System = "system",
};

export interface VariablesFormProps {
  reset: number;
  data: Variable[];
  submitCallback: (val: VariablesObject) => void;
  cancelCallback: () => void;
  touchCallback?: (val: boolean) => void;
}

export const variableTypes: OptionItem[] = [
  { value: VariableType.UrlParam as any, label: `URL Param` },
  { value: VariableType.Value as any, label: `Variable` },
  { value: VariableType.Counter as any, label: `Counter` },
];

export interface VariableManagerModalProps {
  setOpen: (val: boolean) => void;
  surveyId?: string;
  open: boolean;
}

export const QUESTION = "question";
export const H_VAR = "h_var";
export const C_VAR = "c_var";
export const CUSTOM_VARIABLE = "custom_var";
export const QUOTA = "quota";

export const H_VAR_LABEL = "System Variable";
export const C_VAR_LABEL = "Contact Variable";
export const CUSTOM_VARIABLE_LABEL = "Custom Variable";
export const QUOTA_LABEL = "Quota";

export const VariableTypes = [CUSTOM_VARIABLE, H_VAR, C_VAR, QUOTA];

export enum HVariable {
  FraudRisk = "h_fraudrisk",
  IdDuplicate = "h_idduplicate",
  ReCaptchaV3 = "h_recaptchaV3",
  PanelId = "h_pid",
  Flags = "h_flags",
  FinalStatus = "h_finalstatus",
  DQStatus = "h_dqstatus",
  DQReason = "h_dqreason",
  RevIdDuplicateScore = "h_revdupscore",
};

export enum CVariable {
  ContactId = "c_contactId",
  Email = "c_email",
  FirstName = "c_firstName",
  LastName = "c_lastName",
  PhoneNumber1 = "c_phoneNumber1",
};

export interface Variables {
  [HVariable.DQStatus]?: string;
  [HVariable.DQReason]?: string;
  [HVariable.FraudRisk]?: number;
  [HVariable.IdDuplicate]?: number;
  [HVariable.ReCaptchaV3]?: number;
  [HVariable.PanelId]?: string;
  [HVariable.Flags]?: number;
  [HVariable.FinalStatus]?: string;
  [HVariable.RevIdDuplicateScore]?: number;

  [CVariable.ContactId]?: string;
  [CVariable.Email]?: string;
  [CVariable.FirstName]?: string;
  [CVariable.LastName]?: string;
  [CVariable.PhoneNumber1]?: string;
};

export const VariablesDefaults = {
  [HVariable.DQStatus]: "",
  [HVariable.DQReason]: "",
  [HVariable.FraudRisk]: 0,
  [HVariable.IdDuplicate]: "",
  [HVariable.ReCaptchaV3]: "",
  [HVariable.PanelId]: "",
  [HVariable.Flags]: "",
  [HVariable.FinalStatus]: "",
  [HVariable.RevIdDuplicateScore]: 0,

  [CVariable.ContactId]: "",
  [CVariable.Email]: "",
  [CVariable.FirstName]: "",
  [CVariable.LastName]: "",
  [CVariable.PhoneNumber1]: "",
};

export const hVariableOptionItems: OptionItem[] = [
  { value: HVariable.FraudRisk, label: `Fraud Risk` },
  { value: HVariable.IdDuplicate, label: `Id Duplicate` },
  { value: HVariable.PanelId, label: `Panel Id` },
  { value: HVariable.Flags, label: `Flags` },
  { value: HVariable.FinalStatus, label: `Final Status` }
];

export const cVariableOptionItems: OptionItem[] = [
  { value: CVariable.ContactId, label: `Contact Id` },
  { value: CVariable.Email, label: `Email` },
  { value: CVariable.FirstName, label: `First Name` },
  { value: CVariable.LastName, label: `Last Name` },
  { value: CVariable.PhoneNumber1, label: `Phone Number 1` }
];

export const hVariableSettableOptionItems: OptionItem[] = [
  { value: HVariable.PanelId, label: `Panel Id` },
  { value: HVariable.Flags, label: `Flags` },
  { value: HVariable.FinalStatus, label: `Final Status` },
];

export const ValueTypes: OptionItem[] = [
  { value: ExpressionType.CustomValue, label: `Custom Value` },
  { value: ExpressionType.PredefinedValue, label: `Predefined Values` },
  { value: ExpressionType.CustomValueList, label: `Custom List` },
  { value: ExpressionType.DynamicText, label: `Dynamic Text` },
];

export const expressionTypes: OptionItem[] = [
  { value: ExpressionType.CustomValue, label: `Custom Value` },
  { value: ExpressionType.DynamicText, label: `Dynamic Text` },
  { value: ExpressionType.Variable, label: `Variable` },
];

export enum IdentifierTypes {
  Question = "question",
  Variable = "variable",
}

export const identifierTypes: OptionItem[] = [
  { value: IdentifierTypes.Question as any, label: `Question` },
  { value: IdentifierTypes.Variable as any, label: `Variable` },
];

export interface IdentifierFormItem {
  identifierType: IdentifierTypes;
  identifier: string;
  expressionType: string;
  value: string;
}

export enum VariablePages {
  ChooseVariable = "choose-variable",

  AddVariable = "add-variable",

  CustomVariable = "custom-variable",
  UrlVariable = "url-variable",
  CounterVariable = "counter-variable",
  SystemVariable = "system-variable",

  ViewCustomVariables = "view-custom-variables",
  ViewUrlVariables = "view-url-variables",
  ViewCounterVariables = "view-counter-variables",
  ViewSystemVariables = "view-system-variables",
};

export enum QuotaPages {
  AddQuota = "add-quota",
};