import { Action } from '../utils/flowEngineRules';

export enum FlowBlockSize {
  Small = "small",
}

export enum FlowBlockTypes {
  Question = "question",
  EndSurvey = "endSurvey",
  Section = "showPage",
  Randomize = "randomize",
  Step = "step",
  SetIdentifier = "setIdentifier",
  IncrementCounter = "incrementCounter",

  Separator = "separator", // only for UI purposes
  Title = "title", // only for UI purposes
  AddItem = "add-item", // only for UI purposes
}

export enum FlowBlockActionsGeneral {
  AddAfter = "add-after",
  AddBefore = "add-before",
  AddChild = "add-child",
  Edit = "edit",
  Move = "move",
  Delete = "delete",
  Duplicate = "duplicate",
}

export enum FlowBlockActions {
  AddPage = "add-page",
  AddEndSurvey = "add-end-survey",
  AddBranch = "add-branch",
  AddIncrementCounter = "add-increment-counter",
  AddSetIdentifier = "add-set-identifier",
  MoveUp = "move-up",
  MoveDown = "move-down",
  Update = "update",
  Delete = "delete",
}

export enum Direction {
  Up = "up",
  Down = "down",
}

export interface ActionItem {
  type: string,
  action: () => any
}

export interface FlowEngineModalProps {
  setOpen: (val: boolean) => void;
  surveyId?: string;
  open: boolean;
  node: any;
  action: FlowBlockActionsGeneral;
  submitCallback: (val: boolean) => void;
}

export type Block = Action & {
  id?: any;
  component?: any;
  actions: Block[];
} & any;

export enum ActiveSurveyElements {
  Page = "Page",
  Question = "Question",
  FlowEngineBlock = "FlowEngineBlock",
}